import React, { FC, ReactNode } from "react";
import styled from "@emotion/styled";

const Item = styled.div`
  scroll-snap-align: center;
  min-width: 100%;
`;

const CarouselItem: FC<{ id: string; zIndex: number; children: ReactNode }> = ({
  children,
  id,
  zIndex
}) => (
  <Item id={id} style={{ zIndex }}>
    {children}
  </Item>
);

export default CarouselItem;
