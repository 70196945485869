import styled from "@emotion/styled";

const EmptyResultsState = styled.div`
  padding: 1rem 1rem 0.875rem;
  background: var(--Secondary-Dark-Cream, #f2efe7);
  border-radius: 0.5rem;

  @media screen and (min-width: 768px) {
    padding: 1.5rem;
  }
`;

export default EmptyResultsState;
