import React from "react";
const IconCross = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    fill="none"
    viewBox="0 0 25 24"
  >
    <path
      fill="#fff"
      d="M23.49 3.377 3.377 23.488A1.69 1.69 0 1 1 .989 21.1L21.1.988a1.69 1.69 0 1 1 2.39 2.39Z"
    />
    <path
      fill="#fff"
      d="m3.927.795 20.11 20.11a1.69 1.69 0 0 1-2.388 2.39L1.538 3.184A1.69 1.69 0 0 1 3.927.794Z"
    />
  </svg>
);
export default IconCross;
