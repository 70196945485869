import React, { useMemo } from "react";
import { keyframes } from "@emotion/react";
import { FC } from "react";
import styled from "@emotion/styled";
import IconCoachingProfile from "@9amhealth/shared/icons/IconCoachingProfile";
import { TaskResponse } from "@9amhealth/openapi/generated/models/TaskResponse";
import IconLock from "@9amhealth/shared/icons/IconLock";
import IconCheck from "@9amhealth/shared/icons/IconCheck";
import IconCross from "@9amhealth/shared/icons/IconCross";
import { UserJourneyLessonItem, UserJourneyModule } from "./UserJourneyBloc";

export const VisualText = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  transform: translateY(3%);
  justify-content: center;
  color: var(--Greys-Charcoal60, rgba(33, 33, 33, 0.6));
  font-size: 3.4rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.07rem;
`;

export const VisualOverlay = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.4rem solid var(--border-color);
  background: var(--overlay-color);
`;

const pulseScale = keyframes`
  0% { transform: scale(1); opacity: 1; }
  20% { transform: scale(1.4); opacity: 0; }
  20.1% { transform: scale(1); opacity: 0; }
  20.2% { transform: scale(1); opacity: 0.8; }
  40% { transform: scale(1.35); opacity: 0; }
  40.1% { transform: scale(1); opacity: 0; }
  40.2% { transform: scale(1); opacity: 0.6; }
  60% { transform: scale(1.3); opacity: 0; }
  100% { transform: scale(1.3); opacity: 0; }
`;

const ButtonVisualBackground = styled.div`
  position: relative;
  width: min(100%, 7.5rem);
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--Greys-White, #fff);
  display: flex;
  z-index: 0;
  align-items: center;
  justify-content: center;
  --border-width: 0.4rem;
  --border-color: white;
  --overlay-color: rgba(0, 0, 0, 0.2);

  &[data-color-scheme="sunrise"] {
    --gradient-bg: linear-gradient(
      0deg,
      #80aeff 0%,
      #f7bde6 52.68%,
      #ffbd70 100%
    );
    --gradient-bg-2: linear-gradient(
      308deg,
      #ffbd70 0%,
      #f7bde6 52.68%,
      #ffbd70 100%
    );
    --overlay-ball-bg: #ffbd70;
  }

  &[data-color-scheme="blue"] {
    --gradient-bg: linear-gradient(
      0deg,
      #ffeff9 0%,
      #cfe0fc 10.3%,
      #abd4ff 48.9%,
      #d2e7fa 67.7%,
      #fffcf3 88%
    );
    --overlay-ball-bg: linear-gradient(
      0deg,
      #ffeff9 0%,
      #cfe0fc 10.3%,
      #abd4ff 56.9%,
      #d2e7fa 70.7%,
      #fffcf3 99%
    );
  }

  &[data-color-scheme="green"] {
    --gradient-bg: linear-gradient(
      0deg,
      #ffefc7 0%,
      #b0f2ce 55.68%,
      #fffcf3 100%
    );
    --overlay-ball-bg: linear-gradient(
      0deg,
      #ffefc7 0%,
      #b0f2ce 45.68%,
      #fffcf3 100%
    );
  }

  &[data-variant="gradient"] {
    box-shadow:
      -82px 81px 46px 0px rgba(69, 49, 22, 0.01),
      -46px 45px 39px 0px rgba(69, 49, 22, 0.03),
      -20px 20px 29px 0px rgba(69, 49, 22, 0.07),
      -5px 5px 16px 0px rgba(69, 49, 22, 0.08),
      0px 0px 0px 0px rgba(69, 49, 22, 0.08);
  }

  &[data-variant="icon"] {
    background: var(--Primary-Cream, #fffcf3);
    --border-color: var(--Secondary-Dark-Cream, #f2efe7);
    --gradient-bg: var(--color-cream);
    --gradient-bg-2: transparent;
    --overlay-ball-bg: transparent;
  }

  &[data-status="AVAILABLE"],
  &[data-status="IN_PROGRESS"] {
    --overlay-color: rgba(0, 0, 0, 0);
    --pulse-color: #ffbd70aa;
  }

  svg {
    width: 55%;
    height: auto;
    position: relative;
    z-index: 3;
    fill: var(--Primary-Cream, #fffcf3);
  }

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    border-radius: 50%;
    opacity: 1;
    background: var(--pulse-color, transparent);
    animation: ${pulseScale} 3.5s ease-in-out infinite;
  }
`;

const move = keyframes`
  from { --n: 0; }
  to { --n: 1; }
`;

const ButtonVisualGradient = styled.div`
  position: absolute;
  inset: 0;
  border-radius: 50%;
  background: var(--gradient-bg);
  overflow: hidden;
  animation: ${move} both;
  animation-timeline: view();
  animation-range: cover 10% cover 100%;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: var(--gradient-bg-2);
    border-radius: 50%;
    opacity: 0.3;
  }

  :after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: calc(var(--n) * -50% + 100%);
    transform: translateX(-50%);
    transform-origin: top;
    width: 100%;
    height: 100%;
    background: var(--overlay-ball-bg);
    border-radius: 50%;
    opacity: 1;
  }
`;

const ButtonVisualInner = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 5;
`;

const VisualIconWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
  --max-size: 1.8rem;

  svg {
    position: relative;
    z-index: 3;
    width: auto;
    height: auto;
    max-width: var(--max-size);
    max-height: var(--max-size);
    filter: drop-shadow(-4px 4px 4px rgba(0, 0, 0, 0.6));
  }
`;

export const VisualIcon: FC<{
  status?: TaskResponse.status;
  className?: string;
}> = (props) => {
  return (
    <VisualIconWrap className={props.className}>
      {props.status === TaskResponse.status.LOCKED && <IconLock />}
      {props.status === TaskResponse.status.COMPLETED && <IconCheck />}
      {props.status === TaskResponse.status.SKIPPED && <IconCross />}
    </VisualIconWrap>
  );
};

const colorSchemes = ["sunrise", "blue", "green"];

export const ButtonVisual: FC<{
  children: React.ReactNode;
  item: UserJourneyLessonItem;
  module: UserJourneyModule;
}> = (props) => {
  const isQuestionnaire = props.item.type === "typeform";
  const isCoaching = props.item.type === "coaching";
  const variant = isQuestionnaire ? "gradient" : "icon";
  const colorScheme = colorSchemes[props.module.index % colorSchemes.length];

  const status = useMemo(() => {
    const status = props.item.task?.status;
    if (isCoaching && status !== TaskResponse.status.AVAILABLE) {
      return TaskResponse.status.COMPLETED;
    }

    return status;
  }, [props.item.task?.status, isCoaching]);

  return (
    <ButtonVisualBackground
      data-variant={variant}
      data-status={status}
      data-color-scheme={colorScheme}
      className="button-background"
    >
      <ButtonVisualGradient />
      {props.item.type === "coaching" && <IconCoachingProfile />}
      <ButtonVisualInner className="button-inner">
        {props.children}
      </ButtonVisualInner>
    </ButtonVisualBackground>
  );
};
