import { DeviceResponse } from "@9amhealth/openapi";
import translate from "src/lib/translate";
import devicesBox from "src/ui/assets/images/medical-devices-box.jpg";

export const deviceTypeToNameMap: Record<DeviceResponse.type, string | null> = {
  [DeviceResponse.type.BLOOD_PRESSURE_MONITOR]: translate(
    "device.blood_pressure_monitor"
  ),
  [DeviceResponse.type.GLUCOMETER]: translate("device.blood_glucose_meter"),
  [DeviceResponse.type.CGM]: translate("device.cgm"),
  [DeviceResponse.type.WEIGHT_SCALE]: translate("device.weight_scale"),
  [DeviceResponse.type.TAPE_MEASURE]: translate("device.tape_measure"),
  [DeviceResponse.type.MOBILE_PHONE]: null
};

export interface DeviceImage {
  src: string;
  alt: string;
}

export const deviceTypeToImageMap: Record<
  DeviceResponse.type | "default",
  DeviceImage | null
> = {
  [DeviceResponse.type.BLOOD_PRESSURE_MONITOR]: {
    src: "https://cdn.sanity.io/images/g38rxyoc/production/1f8cdbe876f8bc328eaae602ab7da5d5514d0de4-300x300.png",
    alt: translate("device.image_alt.blood_pressure_monitor")
  },
  [DeviceResponse.type.GLUCOMETER]: {
    src: "https://cdn.sanity.io/images/g38rxyoc/production/13ead2849626f96e8707f7dfecb8d91435b87e2b-300x300.png",
    alt: translate("device.image_alt.blood_glucose_meter")
  },
  [DeviceResponse.type.CGM]: {
    src: "https://cdn.sanity.io/images/g38rxyoc/production/d732403087a8c554a9a5ba61fb81ae4234ad6b0c-300x300.png",
    alt: translate("device.image_alt.cgm")
  },
  [DeviceResponse.type.WEIGHT_SCALE]: {
    src: "https://cdn.sanity.io/images/g38rxyoc/production/1c625b8c695c15712580781220edd24c836dc16d-300x300.png",
    alt: translate("device.image_alt.weight_scale")
  },
  [DeviceResponse.type.TAPE_MEASURE]: {
    src: "https://cdn.sanity.io/images/g38rxyoc/production/31610cbbfc1351595f264ebca1698014f5ca0dd4-300x300.png",
    alt: translate("device.image_alt.tape_measure")
  },
  [DeviceResponse.type.MOBILE_PHONE]: null,
  ["default"]: {
    src: devicesBox,
    alt: translate("device.image_alt.device_box")
  }
};
