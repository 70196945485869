import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC } from "react";
import React, { useEffect } from "react";

import {
  APP_BREAKPOINT,
  signupHeaderHeightDesktop
} from "src/constants/layout";
import StatusBarBloc from "src/hybrid/components/StatusBarBloc";
import { useBloc } from "src/state/state";

interface Props {
  title: string;
  message?: string;
  buttonText?: string;
  visible?: boolean;
  textCenter?: boolean;
  dangerBackground?: boolean;
  action?: () => Promise<void>;
}

const Wrapper = styled.div<{ dangerBackground?: boolean }>`
  background: ${({ dangerBackground }): string =>
    dangerBackground
      ? "var(--color-danger)"
      : "var(--color-goldenhour-orange)"};
  padding: var(--space-xs) calc(var(--space-xs) * 1.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: calc(var(--space-sm) * 1.5);
  z-index: 6;
  width: 100%;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  margin: 0 auto;

  &.text-center {
    justify-content: center;
    text-align: center;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    border-radius: 0.5rem;
    justify-content: center;
    width: calc(100% - (var(--space-md) * 2));
    top: ${signupHeaderHeightDesktop}px;
  }
`;

const VerticalLine = styled.div`
  &::before {
    content: "";
    display: block;
    width: 0.5px;
    height: calc(var(--space-xs) * 1.5);
    background: var(--color-charcoal);
  }
`;

const Prompt: FC<Props> = ({
  title,
  message,
  action,
  visible,
  buttonText,
  textCenter,
  dangerBackground = false
}) => {
  const [, { resetThemeDefault }] = useBloc(StatusBarBloc);
  const color = dangerBackground ? "#ee968a" : "#ffdeb8";

  useEffect(() => {
    if (!visible) {
      return;
    }

    StatusBarBloc.temporaryThemeColor = color;
    resetThemeDefault();
    return () => {
      StatusBarBloc.temporaryThemeColor = null;
      resetThemeDefault();
    };
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <Wrapper
        dangerBackground={dangerBackground}
        className={clsx({ "text-center": textCenter }, "nine-prompt")}
      >
        <p className="as-little m0">
          <span className="as-strong">{title}</span>
          {message?.length && (
            <span style={{ wordBreak: "break-all" }}>{" " + message}</span>
          )}
        </p>

        {buttonText && (
          <>
            <VerticalLine />
            <nine-button
              arrow=""
              padding="equal"
              size="sm"
              variant="fill"
              color="black"
              className="as-little strong"
              onClick={() => void action?.()}
            >
              {buttonText}
            </nine-button>
          </>
        )}
      </Wrapper>
    </>
  );
};

export default Prompt;
