import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import type { ProgramModuleItem } from "src/state/ProgramBloc/ProgramBloc";
import ProgramBloc from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import AppSubPage from "src/ui/components/AppSubPage/AppSubPage";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import MediaPlayer from "src/ui/components/MediaPlayer/MediaPlayer";
import ModuleItemStatusDetails from "src/ui/components/ModuleItemSmall/ModuleItemStatusDetails";
import ProgramModuleDetail from "src/ui/components/ProgramModuleDetail/ProgramModuleDetail";
import ProgramModules from "src/ui/components/ProgramModules/ProgramModules";
import ProgramStats from "src/ui/components/ProgramStats/ProgramStats";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import translate from "../../../lib/translate";

const InlineTitle = styled.span`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  justify-content: space-between;

  span {
    margin-top: 0;
  }
`;

const Ellipse = styled.span`
  /* ellipse */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MediaPlayerFullWidthMobile = styled.div`
  width: min(100vw, 800px);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -0.7rem;

  @media (min-width: 800px) {
    border-radius: 0.6rem;
    overflow: hidden;
    margin-top: 0;
    box-shadow: var(--light-shadow);
  }
`;

const ProgramDetails: FC = () => {
  const { program, module, task } = useParams<{
    program: string;
    module?: string;
    task?: string;
  }>();
  const [, { getProgramContent, validateProgramSlug, selectProgramModule }] =
    useBloc(ProgramBloc);
  const programName = validateProgramSlug(program);
  const navigate = useNavigate();

  if (!programName) {
    return null;
  }

  const nextUp = selectProgramModule(programName, [
    { status: TaskResponse.status.AVAILABLE },
    { status: TaskResponse.status.IN_PROGRESS },
    { status: TaskResponse.status.LOCKED }
  ]);

  const { details, allTasks = [] } =
    ProgramBloc.getModuleTaskRepresentative(nextUp) ?? {};

  const videoTask = allTasks.find((t) => t.details.videoId);

  const programContent = getProgramContent(programName);
  const { title, detailText } = programContent;

  const nextUpIsAvailable =
    nextUp?.status === TaskResponse.status.AVAILABLE ||
    nextUp?.status === TaskResponse.status.IN_PROGRESS;

  const videoTitle = nextUpIsAvailable ? (
    `${nextUp.moduleNumber}. ${nextUp.title}`
  ) : (
    <InlineTitle>
      <Ellipse>
        {nextUp?.moduleNumber}. {nextUp?.title}
      </Ellipse>
      <ModuleItemStatusDetails taskDetails={details} status={nextUp?.status} />
    </InlineTitle>
  );

  const openModuleDetails = (
    targetModule: ProgramModuleItem | undefined = nextUp
  ) => {
    if (!targetModule) return;

    // this will open the ProgramModuleDetail
    navigate(`/app/program/${programName}/${targetModule.taskReference}`);
  };

  return (
    <>
      <Helmet>
        <title>{`${translate("page_name")} - ${title}`}</title>
      </Helmet>
      <ProgramModuleDetail program={programName} module={module} task={task} />

      <AppSubPage>
        <OverlayBackButton show fallbackUrl={`/app/plan`} />
        <AppPageTitle title={title}></AppPageTitle>

        <MediaPlayerFullWidthMobile>
          <MediaPlayer
            title={videoTitle}
            thumb={nextUp?.image}
            imgY={0}
            videoId={videoTask?.details.videoId}
            videoProvider={videoTask?.details.videoProvider}
            status={nextUpIsAvailable ? "ready" : "locked"}
            onPlay={openModuleDetails}
            initPlayer={false}
          />
        </MediaPlayerFullWidthMobile>

        <nine-spacer s="lg"></nine-spacer>

        <AsyncContent check={[title]} height="2em">
          <h5 className="as-strong m0">{title}</h5>
        </AsyncContent>

        <nine-spacer s="sm"></nine-spacer>

        <AsyncContent check={[detailText]} height="4em">
          <p className="color-c-80 m0">{detailText}</p>
        </AsyncContent>

        <nine-spacer s="lg"></nine-spacer>

        <ProgramStats program={programName} />

        <nine-spacer s="md"></nine-spacer>

        <ProgramModules
          programContent={programContent}
          onClick={openModuleDetails}
        />
      </AppSubPage>
    </>
  );
};

export default ProgramDetails;
