import { ShipmentItemResponse, ShipmentResponse } from "@9amhealth/openapi";
import { AVERAGE_DELIVERY_LENGTH_IN_DAYS } from "src/constants/misc";
import { dateLocal, dateLocalString } from "src/lib/date";
import translate from "src/lib/translate";
import type = ShipmentItemResponse.type;
import type { TranslationKey } from "src/types/translationKey";

const { status } = ShipmentResponse;

const uniqBy = <T>(arr: T[], key: keyof T): T[] => {
  const seen = new Set();
  return arr.filter((item) => {
    const k = item[key];
    return seen.has(k) ? false : seen.add(k);
  });
};

interface ShipmentItem {
  title: string;
  date: string;
}

const getShipmentTitleByItemType = (itemType: type): TranslationKey => {
  switch (itemType) {
    case ShipmentItemResponse.type.TEST_STRIP:
      return "shipments_list_tile_strips";
    case ShipmentItemResponse.type.LAB_TEST_KIT:
      return "shipments_list_tile_labs";
    default:
      return "shipments_list_tile_medications";
  }
};

export const mapShipmentsToShipmentsList = (
  shipments: ShipmentResponse[],
  includedItemTypes: ShipmentItemResponse.type[] = [
    type.TEST_STRIP,
    type.LAB_TEST_KIT,
    type.MEDICATION
  ]
): ShipmentItem[] => {
  const shipmentItems: ShipmentItem[] = [];

  shipments.forEach((shipment) => {
    // allow only active shipments
    if (![status.INITIATED, status.TRANSIT].includes(shipment.status)) return;

    const date = dateLocal(shipment.createdAt).add(
      AVERAGE_DELIVERY_LENGTH_IN_DAYS,
      "d"
    );

    const formattedDate = dateLocalString(date);

    // Get only type based unique shipment items
    const uniqueItems = uniqBy(shipment.items, "type");

    uniqueItems.forEach((item) => {
      // Prevent displaying shipment on list for other types than specified
      if (!includedItemTypes.includes(item.type)) return;

      shipmentItems.push({
        title: translate(getShipmentTitleByItemType(item.type), {
          quantity: item.quantity
        }),
        date: formattedDate
      });
    });
  });

  return shipmentItems;
};
