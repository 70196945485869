import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import type {
  ProgramContent,
  ProgramModuleItem
} from "src/state/ProgramBloc/ProgramBloc";
import ModuleItemSmall from "src/ui/components/ModuleItemSmall/ModuleItemSmall";

const Wrap = styled.div`
  display: flex;
  padding: 1em 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: stretch;
`;

const ProgramModules: FC<{
  programContent: ProgramContent;
  onClick?: (module: ProgramModuleItem) => void;
}> = (props) => {
  const modules = props.programContent.moduleItems ?? [];
  return (
    <Wrap>
      {modules.map((module, index) => (
        <ModuleItemSmall key={index} module={module} onClick={props.onClick} />
      ))}
    </Wrap>
  );
};

export default ProgramModules;
