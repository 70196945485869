import type { FC, ReactNode } from "react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LabColor } from "src/constants/LabColor";
import { LoincCodingCode } from "src/constants/fhir";
import {
  IconBloodPressureHeartWithClock,
  IconHeartBeatActivitySteps,
  IconLabsBloodGlucoseDropWithSugarCube,
  IconWeightScale
} from "src/constants/icons";
import type { CustomObservation, ParsedObservationData } from "src/lib/fhir";
import Fhir from "src/lib/fhir";
import translate from "src/lib/translate";
import LoadingCubit from "src/state/LoadingCubit/LoadingCubit";
import { healthSyncState, toast, useBloc } from "src/state/state";
import { TranslationKey } from "src/types/translationKey";
import IconMeasureRuler from "src/ui/assets/icons/IconMeasureRuler";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import Translate from "src/ui/components/Translate/Translate";
import {
  AppPopup,
  AppQueryPopupsController
} from "./AppQueryPopups/AppQueryPopupsBloc";
import EmptyResultsState from "./StyledComponents/EmptyResultsState";

export type ObservationPreviewProps = {
  latest?: ParsedObservationData;
  history: CustomObservation[];
  obs: CustomObservation[];
  codes: LoincCodingCode[];
};

export function prepareObservationsForPreview(
  obs: CustomObservation[],
  codes: LoincCodingCode[]
): ObservationPreviewProps {
  const all = Fhir.filterObservations(obs, {
    codes: codes
  });
  return {
    latest: Fhir.autoParseObservation(Fhir.getLatestObservation(all)),
    history: all,
    obs,
    codes
  };
}

type ReadingMetaData = {
  color: LabColor;
  icon: ReactNode;
  title: string;
  link: string;
  defaultUnits: string;
};

const bloodGlucoseMeta: ReadingMetaData = {
  color: LabColor.BLOOD_GLUCOSE,
  icon: <IconLabsBloodGlucoseDropWithSugarCube />,
  title: translate("bloodGlucose"),
  link: [
    LoincCodingCode.bloodGlucoseCapillary,
    LoincCodingCode.bloodGlucoseCapillaryFasting
  ].join(","),
  defaultUnits: "mg/dL"
};

const bloodPressureMeta: ReadingMetaData = {
  color: LabColor.BLOOD_PRESSURE,
  icon: <IconBloodPressureHeartWithClock />,
  title: translate("bloodPressure"),
  link: [
    LoincCodingCode.bloodPressure,
    LoincCodingCode.bloodPressureWithChildrenOptional
  ].join(","),
  defaultUnits: "mmHg"
};

const stepsInDayMeta: ReadingMetaData = {
  color: LabColor.ACTIVITY,
  icon: <IconHeartBeatActivitySteps />,
  title: translate("observations.stepsInDay"),
  link: LoincCodingCode.stepsInDay,
  defaultUnits: ""
};

const codeMeta: Record<string, ReadingMetaData> = {
  [LoincCodingCode.bloodGlucoseCapillary]: bloodGlucoseMeta,
  [LoincCodingCode.stepsInDay]: stepsInDayMeta,
  [LoincCodingCode.bloodGlucoseCapillaryFasting]: bloodGlucoseMeta,
  [LoincCodingCode.bloodPressure]: bloodPressureMeta,
  [LoincCodingCode.bloodPressureWithChildrenOptional]: bloodPressureMeta,
  [LoincCodingCode.waistCircumference]: {
    color: LabColor.WAIST_CIRCUMFERENCE,
    icon: <IconMeasureRuler />,
    title: translate("waistCircumference"),
    link: [LoincCodingCode.waistCircumference].join(","),
    defaultUnits: '"'
  },
  [LoincCodingCode.weight]: {
    color: LabColor.WEIGHT,
    icon: <IconWeightScale />,
    title: translate("weight"),
    link: [LoincCodingCode.weight].join(","),
    defaultUnits: "lbs"
  }
};

const DataTrendPreviewCard: FC<{
  observations?: CustomObservation[];
}> = (props) => {
  const observationsPassed = typeof props.observations !== "undefined";
  const { observations = [] } = props;
  const [allLoadingKeys] = useBloc(LoadingCubit);
  const loading =
    allLoadingKeys.includes("loadingLabResults") && observations.length === 0;
  const navigate = useNavigate();

  const handleClick = (reading: ObservationPreviewProps) => {
    const { latest } = reading;
    const { code = reading.codes[0] } = latest
      ? Fhir.localObservationData(latest)
      : {};
    const { link } = codeMeta[code as LoincCodingCode] ?? {};

    if (latest) {
      navigate(`/app/lab-results/list-codes/${link}`);
      return;
    } else {
      switch (link) {
        case codeMeta[LoincCodingCode.bloodGlucoseCapillary].link:
        case codeMeta[LoincCodingCode.bloodGlucoseCapillaryFasting].link:
          AppQueryPopupsController.openPopup(AppPopup.enterBloodGlucose);
          break;
        case codeMeta[LoincCodingCode.waistCircumference].link:
          AppQueryPopupsController.openPopup(AppPopup.enterWaistCircumference);
          break;
        case codeMeta[LoincCodingCode.weight].link:
          AppQueryPopupsController.openPopup(AppPopup.enterWeight);
          break;
        case codeMeta[LoincCodingCode.bloodPressure].link:
          AppQueryPopupsController.openPopup(AppPopup.enterBloodPressure);
          break;
        case codeMeta[LoincCodingCode.stepsInDay].link:
          if (!healthSyncState.isAvailable) {
            toast.show("health.syncNotAvailable");
            return;
          }

          if (!healthSyncState.stepsAuthorized) {
            AppQueryPopupsController.openPopup(AppPopup.healthSyncSetup);
            return;
          }
          break;
        default:
          toast.show("readings.noValues");
      }
    }
  };

  const groups = useMemo(
    () => [
      prepareObservationsForPreview(observations, [
        LoincCodingCode.bloodGlucoseCapillary,
        LoincCodingCode.bloodGlucoseCapillaryFasting
      ]),
      prepareObservationsForPreview(observations, [
        LoincCodingCode.bloodPressure,
        LoincCodingCode.bloodPressureWithChildrenOptional
      ]),
      prepareObservationsForPreview(observations, [LoincCodingCode.stepsInDay]),
      prepareObservationsForPreview(observations, [
        LoincCodingCode.waistCircumference
      ]),
      prepareObservationsForPreview(observations, [LoincCodingCode.weight])
    ],
    [observations]
  );

  const noValues =
    observationsPassed &&
    groups.every(({ latest }) => typeof latest === "undefined");

  return (
    <>
      <CollectionTitle>
        <Translate msg="readings" />
      </CollectionTitle>

      {noValues && (
        <EmptyResultsState>
          <p className="m0 strong color-c-80">
            <Translate msg="noResultsYet" variables={{ context: "readings" }} />
          </p>
        </EmptyResultsState>
      )}

      <Collection>
        {groups.map((reading) => {
          const { latest } = reading;
          const {
            value = "--",
            unit = undefined,
            code = reading.codes[0]
          } = latest ? Fhir.localObservationData(latest) : {};
          const { title, color, icon, defaultUnits } =
            codeMeta[code as LoincCodingCode] ?? {};
          const timeAgo = latest?.date && Fhir.displayTimeAgo(latest.date);
          const unitText = unit ?? defaultUnits;

          return (
            <CollectionItemLink
              key={code}
              icon={icon}
              label={title}
              size="large"
              status={
                <small>
                  {loading ? (
                    translate("loading")
                  ) : timeAgo ? (
                    <>{timeAgo}</>
                  ) : (
                    <>
                      <Translate msg="placeholder.noDataAvailable" />
                    </>
                  )}
                </small>
              }
              detail={
                <span>
                  <b>{loading ? "" : value}</b>{" "}
                  <small>
                    {value !== "--" &&
                      translate(
                        "observationUnits",
                        {
                          context: unitText,
                          count: parseInt(value, 10)
                        },
                        unitText as TranslationKey
                      )}
                  </small>
                </span>
              }
              color={color}
              onClick={() => handleClick(reading)}
            />
          );
        })}
      </Collection>
    </>
  );
};

export default DataTrendPreviewCard;
