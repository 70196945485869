import { Button } from "@9amhealth/shared";
import { useBloc } from "@blac/react";
import type { FC } from "react";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { LoincCodingCode } from "src/constants/fhir";
import loincCodeToName from "src/lib/loincCodeToName";
import { TranslationKey } from "src/types/translationKey";
import Translate from "src/ui/components/Translate/Translate";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";
import ChartNotAvailableOverlay from "./ChartNotAvailableOverlay";
import DataHubCubit from "./DataHubCubit";

const ChartEmptyState: FC = () => {
  const [{ activeLoincCode }, { setActiveObservations, clearApiCache }] =
    useBloc(DataHubCubit);
  const navigate = useNavigate();
  const name = loincCodeToName(activeLoincCode);

  const buttonText = useMemo((): TranslationKey => {
    switch (activeLoincCode) {
      case LoincCodingCode.weight:
        return "dataHub.addEntry";
      case LoincCodingCode.waistCircumference:
        return "dataHub.addEntry";
    }
    return "chatWithCareTeam";
  }, [activeLoincCode]);

  const handlePopupClose = () => {
    clearApiCache(activeLoincCode);
    void setActiveObservations({
      loincCode: activeLoincCode
    });
  };

  const handleButtonPress = () => {
    switch (activeLoincCode) {
      case LoincCodingCode.weight:
        return AppQueryPopupsController.openPopup(AppPopup.enterWeight, {
          onEvent: {
            popupClosed: handlePopupClose
          }
        });
      case LoincCodingCode.waistCircumference:
        return AppQueryPopupsController.openPopup(
          AppPopup.enterWaistCircumference,
          {
            onEvent: {
              popupClosed: handlePopupClose
            }
          }
        );
    }

    return navigate(`/app/chat`);
  };

  return (
    <ChartNotAvailableOverlay
      title={
        <Translate msg="dataHub.noData.title" variables={{ context: name }} />
      }
      description={
        <Translate
          msg="dataHub.noData.description"
          variables={{ context: name }}
        />
      }
      after={
        <Button onPress={handleButtonPress}>
          <Translate msg={buttonText} />
        </Button>
      }
    />
  );
};

export default ChartEmptyState;
