import type { FC } from "react";
import React from "react";
import Translate from "src/ui/components/Translate/Translate";
import translate from "src/lib/translate";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import Collection from "src/ui/components/StyledComponents/Collection";
import { checkItemType } from "src/lib/checkItemType";
import {
  ItemType,
  SubscriptionInterval
} from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import {
  PurchaseItemResponse,
  SubscriptionDetailsResponse
} from "@9amhealth/openapi";
import externalItemType = PurchaseItemResponse.externalItemType;
import pillBottle from "src/ui/assets/images/iso-meds-vial.jpg";
import { MedicationRowItem } from "src/ui/components/MedicationRowItem/MedicationRowItem";
import { ParsedPurchaseItemResponse } from "src/lib/parsePurchaseItem";
import { Wrapper } from "./FullSubscriptionPlanOverviewContent";

export const MedicationSection: FC<{
  parsedMeds: ParsedPurchaseItemResponse[];
  subscription?: SubscriptionDetailsResponse;
}> = ({ parsedMeds, subscription }) => {
  return (
    <Wrapper>
      <CollectionTitle>
        <Translate msg="prescriptionMedication" />
      </CollectionTitle>

      <Collection>
        {parsedMeds.map((item) => {
          const isPlaceholderMed = checkItemType(
            item.externalItemType,
            ItemType.PRELIMINARY_MEDICATION_SUGGESTION
          );

          const description = isPlaceholderMed
            ? translate("plan_overview_placeholder_medication_dosage")
            : item.intake;

          const inlineWarning = item.expiredAt
            ? translate("prescription_valid_missing_lab_values_alert")
            : "";

          const showRefill =
            item.externalItemType !== externalItemType.PRESCRIBED_MEDICATION;

          return (
            <MedicationRowItem
              key={item.sku}
              sku={item.sku}
              item={item}
              image={pillBottle}
              duration={subscription?.renewalInterval as SubscriptionInterval}
              description={description}
              inlineWarning={inlineWarning}
              showRefill={showRefill}
              hidePrice
              hideRemove
            />
          );
        })}
      </Collection>
    </Wrapper>
  );
};
