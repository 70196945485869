import type { FC } from "react";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AppSubPage from "src/ui/components/AppSubPage/AppSubPage";
import CustomSuspense from "src/ui/components/CustomSuspense/CustomSuspense";
import { ProfileMenuItem } from "src/ui/components/ProfileOverview/ProfileOverview";
import LanguageSettings from "../LanguageSettings/LanguageSettings";

const Async = {
  AccountInformation: React.lazy(
    async () =>
      import("src/ui/components/AccountInformation/AccountInformation")
  ),
  BillingInformation: React.lazy(
    async () =>
      import("src/ui/components/BillingInformation/BillingInformation")
  ),
  DataSync: React.lazy(
    async () => import("src/ui/components/DataSync/DataSync")
  ),
  DataSyncLearnMore: React.lazy(
    async () => import("src/ui/components/DataSync/DataSyncLearnMore")
  ),
  ShippingInformation: React.lazy(
    async () =>
      import("src/ui/components/ShippingInformation/ShippingInformation")
  ),
  NotificationSettings: React.lazy(
    async () =>
      import("src/ui/components/NotificationSettings/NotificationSettings")
  ),
  InsuranceInformationDetail: React.lazy(
    async () =>
      import(
        "src/ui/components/InsuranceInformationDetail/InsuranceInformationDetail"
      )
  ),
  PharmacyInformationDetail: React.lazy(
    async () =>
      import(
        "src/ui/components/PharmacyInformationDetail/PharmacyInformationDetail"
      )
  ),
  PCPInformationDetail: React.lazy(
    async () =>
      import("src/ui/components/PCPInformationDetail/PCPInformationDetail")
  )
};

const ProfileRouter: FC = () => {
  return (
    <>
      {/* ROUTES FOR SUBPAGES */}
      <Routes>
        <Route
          path={`${ProfileMenuItem.account}/:action?`}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.AccountInformation />
              </CustomSuspense>
            </AppSubPage>
          }
        />
        <Route
          path={`${ProfileMenuItem.billing}/:action?`}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.BillingInformation />
              </CustomSuspense>
            </AppSubPage>
          }
        />
        <Route
          path={ProfileMenuItem.dataSync}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.DataSync />
              </CustomSuspense>
            </AppSubPage>
          }
        />
        <Route
          path={ProfileMenuItem.dataSyncLearnMore}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.DataSyncLearnMore />
              </CustomSuspense>
            </AppSubPage>
          }
        />
        <Route
          path={`${ProfileMenuItem.shipping}/:action?`}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.ShippingInformation />
              </CustomSuspense>
            </AppSubPage>
          }
        />
        <Route
          path={`${ProfileMenuItem.language}/:action?`}
          element={
            <AppSubPage>
              <LanguageSettings />
            </AppSubPage>
          }
        />
        <Route
          path={`${ProfileMenuItem.notifications}/:action?`}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.NotificationSettings />
              </CustomSuspense>
            </AppSubPage>
          }
        />
        {/*  insurance detail */}
        <Route
          path={ProfileMenuItem.insurance}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.InsuranceInformationDetail />
              </CustomSuspense>
            </AppSubPage>
          }
        />
        {/*  pharmacy detail */}
        <Route
          path={ProfileMenuItem.pharmacy}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.PharmacyInformationDetail />
              </CustomSuspense>
            </AppSubPage>
          }
        />
        {/*  pcp detail */}
        <Route
          path={ProfileMenuItem.pcp}
          element={
            <AppSubPage>
              <CustomSuspense>
                <Async.PCPInformationDetail />
              </CustomSuspense>
            </AppSubPage>
          }
        />
      </Routes>
    </>
  );
};

export default ProfileRouter;
