import type { FC } from "react";
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { IconTaskClipboardVial } from "src/constants/icons";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";
import LoadingCubit, { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import ObservationBundleBloc from "src/state/ObservationBundleBloc/ObservationBundleBloc";
import { useBloc } from "src/state/state";
import DataTrendPreviewCard from "src/ui/components/DataTrendPreviewCard";
import LabResultGroupTable from "src/ui/components/LabResultGroupTable";
import Translate from "src/ui/components/Translate/Translate";
import DataHub from "../DataHub/DataHub";
import { useBloc as useBloc2 } from "@blac/react";

const UserLabResults: FC = () => {
  const [{ observations }, { loadObservations }] = useBloc2(LabResultsCubit);
  const [{ observationBundles }, { loadObservationBundles }] = useBloc(
    ObservationBundleBloc
  );

  const observationBundlesLimited = observationBundles?.slice(0, 20);

  const [, { isLoading }] = useBloc(LoadingCubit);
  const loading = isLoading(LoadingKey.loadingLabResults);

  const navigate = useNavigate();
  const navigateToMessages = useCallback(() => {
    navigate(`/app/chat`);
  }, []);

  useEffect(() => {
    void loadObservations();
    void loadObservationBundles();
  }, []);

  return (
    <div>
      {/* Empty state */}
      {!loading && observations?.length === 0 && (
        <div style={{ textAlign: "center", marginTop: "8rem" }}>
          <IconTaskClipboardVial size={80} />
          <nine-spacer s="sm" />

          <h4 className="as-h4-large">
            <Translate msg="placeholder.noData" />
          </h4>
          <p>
            <Translate msg="placeholder.labResultsAndReadings" />
          </p>
          <nine-spacer s="xs" />

          <nine-button onClick={navigateToMessages}>
            <Translate msg="button.contactCareTeam" />
          </nine-button>
          <nine-spacer s="md" />
        </div>
      )}

      {/* Data Hub */}
      <DataHub />

      {/* List readings */}
      {/* <AjdinK> TODO: Remove this once DataHub is complete */}
      <DataTrendPreviewCard observations={observations} />

      <nine-spacer s="lg" />
      <nine-spacer s="xs" />

      {/* List lab results */}
      <LabResultGroupTable observationBundles={observationBundlesLimited} />
    </div>
  );
};

export default UserLabResults;
