import React from "react";
import { FC } from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const dash = keyframes`
  from { stroke-dashoffset: 18; }
  to { stroke-dashoffset: 0; }
`;

const SpacerWrap = styled.div`
  width: 100%;
  color: #e6e3db;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.1rem 0 1.8rem 0;

  svg {
    width: 5px;
    overflow: hidden;
    border-radius: 2rem;
    path {
      stroke-width: 5;
      stroke-dasharray: 6 12;
    }
  }

  &[data-animate="true"] {
    svg {
      path {
        animation: ${dash} 1s linear infinite;
      }
    }
  }
`;

const Spacer: FC<{ animate?: boolean }> = (props) => {
  const { animate } = props;
  const width = 5;
  const height = 60;
  return (
    <SpacerWrap className="spacer" data-animate={animate}>
      <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d={`M${width / 2} 0 L${width / 2} ${height}`}
          stroke="currentColor"
          strokeWidth="7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SpacerWrap>
  );
};
export default Spacer;
