import type { CSSProperties, FC, ReactNode } from "react";
import React from "react";
import styled from "@emotion/styled";
import type { LabColor } from "src/constants/LabColor";
import Translate from "../Translate/Translate";
import { APP_BREAKPOINT } from "src/constants/layout";

const StyledDiv = styled.div`
  border-radius: inherit;
  transition: background-color 0.1s ease-in-out;
  color: inherit;
  text-decoration: inherit;
  position: relative;

  --justifyContent: flex-start;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .image {
    border-radius: 8px;
    background-color: #f2efe7;
    overflow: hidden;
    height: 54px;
    width: 54px;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &[aria-disabled="true"] {
    pointer-events: none;

    .icon,
    .label,
    .arrow {
      opacity: 0.2;
    }
  }

  body &:active {
    background-color: #0000000a;
  }
  body &:hover {
    text-decoration: none;
  }
  .custom {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    svg {
      transform: scale(1.4);
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    flex-direction: row;
  }
`;

const Refill = styled.div`
  align-self: flex-start;
`;

const CollectionItemElement: FC<{
  icon?: ReactNode;
  imageSrc?: string;
  imageAlt?: string;
  label?: ReactNode;
  card?: boolean;
  to?: string;
  onClick?: () => void;
  iconSize?: string;
  disabled?: boolean;
  action?: ReactNode;
  status?: ReactNode;
  detail?: ReactNode;
  color?: LabColor | string;
  size?: "large";
  endIcon?: ReactNode;
  refill?: boolean;
}> = (props) => {
  const { icon, label, iconSize, disabled, action, refill } = props;

  return (
    <StyledDiv
      className="link"
      aria-disabled={disabled ? "true" : "false"}
      style={
        {
          "--icon-size": iconSize,
          "--icon-bg": props.color
        } as CSSProperties
      }
    >
      {icon && (
        <div className="icon" data-size={props.size}>
          {icon}
        </div>
      )}
      {props.imageSrc && (
        <div className="image" data-size={props.size}>
          <img src={props.imageSrc} alt={props.imageAlt} />
        </div>
      )}
      <Wrap>
        <div>
          <div className="label">
            <p className={`${props.card && "as-body2 m0"}`}>{label}</p>
            {props.status && (
              <p className={`${props.card && "as-little color-c-80 m0"}`}>
                {props.status}
              </p>
            )}
          </div>
          <div>
            {props.detail && <div className="detail">{props.detail}</div>}
          </div>
        </div>
        {refill && (
          <div>
            <Refill>
              <nine-pill slot="pill">
                <Translate msg="autoRefill" />
              </nine-pill>
            </Refill>
          </div>
        )}
        {action && <div className="action">{action}</div>}
      </Wrap>
    </StyledDiv>
  );
};
export default CollectionItemElement;
