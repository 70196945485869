import type {
  PurchaseItemResponse,
  SupplyCarePlanActionMetadata
} from "@9amhealth/openapi";
import type { ParsedPurchaseItemResponse } from "src/lib/parsePurchaseItem";

export const getMedicationItemSig = (
  item: ParsedPurchaseItemResponse | PurchaseItemResponse
): string => {
  return (item.metadata as SupplyCarePlanActionMetadata)["supply.sig"] ?? "";
};
