import type { DeviceItem } from "@9amhealth/openapi";
import type { FC } from "react";
import React from "react";
import { deviceTypeToNameMap } from "src/constants/devices";
import translate from "src/lib/translate";
import devicesBox from "src/ui/assets/images/medical-devices-box.jpg";
import CollectionItemElement from "../StyledComponents/CollectionItemElement";

interface Props {
  device: DeviceItem;
  image?: string;
  imageAlt?: string;
}

export const DeviceRowItem: FC<Props> = ({ device, image, imageAlt }) => {
  const defaultImage = {
    src: devicesBox,
    alt: translate("device.image_alt.device_box")
  };
  const text = deviceTypeToNameMap[device.type];
  if (text === null) {
    return null;
  }

  return (
    <CollectionItemElement
      imageSrc={image ?? defaultImage.src}
      imageAlt={imageAlt ?? defaultImage.alt}
      label={text}
      card={true}
    ></CollectionItemElement>
  );
};
