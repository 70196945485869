import React from "react";
import { Routes, Route } from "react-router-dom";
import AppSubPage from "../AppSubPage/AppSubPage";
import translate from "src/lib/translate";
import AppPageTitle from "../AppPageTitle/AppPageTitle";
import OverlayBackButton from "../StyledComponents/OverlayBackButton";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import UserJourneyOverview from "../UserJourneyOverview/UserJourneyOverview";

const PlanRouter = () => {
  return (
    <div>
      <Routes>
        <Route
          path={`/${KnownProgram.LIFEBALANCE}`}
          element={
            <AppSubPage>
              <OverlayBackButton show fallbackUrl={"/app/plan"} />
              <AppPageTitle
                title={translate("lifestyle.title_lifebalance")}
              ></AppPageTitle>
              <nine-spacer s={"sm"}></nine-spacer>
              <UserJourneyOverview program={KnownProgram.LIFEBALANCE} />
            </AppSubPage>
          }
        />
      </Routes>
    </div>
  );
};

export default PlanRouter;
