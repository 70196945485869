import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import type { SanityResolvedDocument } from "src/api/SanityService";
import { DownloadIcon } from "src/constants/icons";

const Wrap = styled.div`
  width: 100%;
  display: flex;
`;

const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 500;
  letter-spacing: -0.02rem;
`;

const Description = styled.div`
  color: var(--greys-charcoal-60, rgba(33, 33, 33, 0.6));
  font-size: 0.75rem;
  font-weight: 400;
`;

const Actions = styled.div``;

const Button = styled.a`
  display: flex;
  padding: 0.5rem;
  align-items: flex-start;
  gap: 0.5rem;
  border-radius: 8px;
  background: var(--secondary-dark-cream, #f2efe7);
  transition: filter var(--ease-time) var(--ease-type);

  &:hover {
    filter: brightness(0.8);
  }
`;

const FileCard: FC<{
  attachment: {
    file?: SanityResolvedDocument;
    name?: string;
    description?: string;
  };
}> = (props) => {
  return (
    <Wrap>
      <Info>
        <Title>{props.attachment.name}</Title>
        <Description>{props.attachment.description}</Description>
      </Info>
      <Actions>
        <Button
          href={props.attachment.file?.url}
          target="_blank"
          rel="noopener noreferrer"
          title={props.attachment.file?.originalFilename}
        >
          <DownloadIcon />
        </Button>
      </Actions>
    </Wrap>
  );
};

export default FileCard;
