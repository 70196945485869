import type { FC } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";

export enum StepStatus {
  done = "done",
  inProgress = "in-progress",
  toDo = "to-do"
}

export interface StatusButtonProps {
  label: string;
  link: string | { pathname: string };
}

interface Props {
  title?: string;
  subtitle?: string;
  titleAffix?: string;
  buttonProps?: StatusButtonProps;
  steps: {
    title: string;
    status: StepStatus;
  }[];
  noWrapper?: boolean;
  icon?: string;
}

const StatusList: FC<Props> = ({
  title = "",
  subtitle = "",
  steps,
  buttonProps,
  noWrapper,
  icon
}) => {
  const navigate = useNavigate();
  const navigateToLink = (): void => {
    const link = buttonProps?.link;
    if (link) {
      navigate(link);
    }
  };

  const renderSteps = (
    <nine-info-section>
      <nine-card
        slot="content"
        grow="true"
        variant="row"
        py="md"
        px="md"
        moreContent="true"
        alwaysShowMore="true"
        extra-large-icon
        icon-background
        icon-padding
        custom-padding
        align-items="flex-start"
      >
        {icon && (
          <div slot="icon">
            {icon === "assessment" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1000"
                height="1000"
                viewBox="0 0 1000 1000"
                fill="none"
              >
                <path
                  fill="#212121"
                  d="M693.779 29.06h-512.83c-30.75 0-55.67 24.93-55.67 55.67v830.54c0 30.75 24.93 55.67 55.67 55.67h638.1c30.75 0 55.67-24.93 55.67-55.67V215.32L693.779 29.06Zm8.75 52.52 121.16 124.73h-95.81c-14.01 0-25.36-11.35-25.36-25.35V81.58h.01Zm-546.93 833.69V84.73c0-14 11.35-25.35 25.35-25.35h477.57l-8.06 251.77c-1.38 39.62-.68 98.77-7.18 199.05h-401.56v30.32h399.47c-1.84 24.98-4.09 52.19-6.9 82.02-.91 9.62-2 19.44-3.46 29.46h-389.11v30.32h383.54c-8.62 38.19-24.5 78.86-56.37 121.61h-327.17v30.32h302.08c-31.26 34.31-73.62 69.83-131.34 106.38h-231.51c-14 0-25.35-11.35-25.35-25.35v-.01Z"
                />
                <path
                  fill="#212121"
                  d="M435 356H236v-3.774c0-17.106 8.669-30.062 26.006-38.867 17.337-8.806 41.835-13.208 73.494-13.208s56.157 4.402 73.494 13.208C426.331 322.164 435 335.12 435 352.226V356ZM366.028 229.585c-8.291 8.302-18.467 12.453-30.528 12.453s-22.237-4.151-30.528-12.453c-8.292-8.302-12.438-18.491-12.438-30.566 0-12.076 4.146-22.264 12.438-30.566C313.263 160.151 323.439 156 335.5 156s22.237 4.151 30.528 12.453c8.292 8.302 12.438 18.49 12.438 30.566 0 12.075-4.146 22.264-12.438 30.566Z"
                />
              </svg>
            )}
            {icon === "dcs" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1000"
                height="1000"
                viewBox="0 0 1000 1000"
                fill="none"
              >
                <path
                  fill="#212121"
                  d="M928.95 68H70.91C41.14 68 17 92.14 17 121.91v573.98c0 29.77 24.14 53.91 53.91 53.91h92.71c13.5 0 24.45 10.95 24.45 24.45v130.78c0 22.02 25.31 34.43 42.71 20.93l186.54-144.59c4.29-3.32 9.55-5.12 14.98-5.12h496.64c29.77 0 53.91-24.14 53.91-53.91V121.91c0-29.77-24.14-53.91-53.91-53.91h.01ZM163.62 720.34H70.91c-13.5 0-24.45-10.94-24.45-24.45V121.91c0-13.5 10.95-24.45 24.45-24.45h798.87c-43.24 65.93-67.15 355.21-205.62 443.83-187.39 119.93-293.11 85.72-446 357.19l-.62.48V774.25c0-29.77-24.14-53.91-53.91-53.91h-.01Z"
                />
              </svg>
            )}
          </div>
        )}

        <div slot="title" className="strong as-subhead m0">
          {title}
        </div>
        <div slot="subtitle" className="as-body m0 color-c-80">
          <nine-spacer s="xxs"></nine-spacer>
          {subtitle}
        </div>
        <div slot="more">
          <nine-wrapper>
            {steps.map((step) => (
              <nine-task status={step.status} key={step.title}>
                <div slot="title">{step.title}</div>
              </nine-task>
            ))}
          </nine-wrapper>
          <nine-spacer s="xs"></nine-spacer>
          <div className="center">
            <nine-button onClick={navigateToLink}>
              {buttonProps?.label}
            </nine-button>
          </div>
        </div>
      </nine-card>
    </nine-info-section>
  );

  return noWrapper ? renderSteps : <div>{renderSteps}</div>;
};

export default StatusList;
