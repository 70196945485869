import styled from "@emotion/styled";

const WidgetButton = styled.button`
  background: var(--Secondary-Dark-Cream, #f2efe7);
  border-radius: 2rem;
  padding: 0.5rem 0.65rem;
  font-size: 0.88rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.35rem;
  position: relative;

  * {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &[data-loading="false"] {
    * {
      opacity: 1;
    }
  }
`;

export default WidgetButton;
