import React, { FC } from "react";
import styled from "@emotion/styled";
import clsx from "clsx";
import { useBloc } from "@blac/react";
import { CarouselCubit } from "./CarouselCubit";

const PaginationWrapper = styled.div`
  border-radius: calc(infinity * 1px);
  background: rgba(33, 33, 33, 0.04);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: absolute;
  left: 0;

  div {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: calc(infinity * 1px);
    margin: 0 0.2rem;
    background: var(--color-gray);
    transition: all 0.2s ease-in-out;

    &.active {
      width: 1.6rem;
      background: var(--color-charcoal-60);
    }
  }
`;

const Pagination: FC<{ elementsCount: number }> = ({ elementsCount }) => {
  const [{ activeIndex }] = useBloc(CarouselCubit);

  return (
    <PaginationWrapper>
      {Array.from({ length: elementsCount }).map((_, index) => (
        <div key={index} className={clsx({ active: index === activeIndex })} />
      ))}
    </PaginationWrapper>
  );
};

export default Pagination;
