import { TaskResponse } from "@9amhealth/openapi";
import { t } from "i18next";
import type { FC, ReactNode } from "react";
import React, { useEffect, useState } from "react";
import type { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import ProgramBloc from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import { formatTimeCountdown } from "src/ui/components/Countdown/Countdown";
import type { TaskItemProps } from "src/ui/components/TaskItem/TaskItem";
import TaskItem from "src/ui/components/TaskItem/TaskItem";

const ProgramTaskItem: FC<{
  program?: KnownProgram;
  select: {
    status?: TaskResponse.status;
  }[];
}> = (props) => {
  const [, { getProgramContent, selectProgramModule }] = useBloc(
    ProgramBloc,
    {}
  );

  const [taskProps, setDetails] = useState<TaskItemProps>({
    title: "",
    description: "",
    to: "",
    status: TaskResponse.status.LOCKED
  });

  const { slug } = getProgramContent(props.program);
  const selectedModule = selectProgramModule(props.program, props.select);
  const { details: taskDetails } =
    ProgramBloc.getModuleTaskRepresentative(selectedModule) ?? {};

  useEffect(() => {
    if (!selectedModule) {
      return;
    }

    let description: ReactNode = selectedModule.description;

    if (taskDetails?.startsInFuture) {
      description = `Scheduled on ${
        taskDetails.availableDate?.toLocaleDateString() ?? ""
      }`;
    }

    if (selectedModule.status === TaskResponse.status.IN_PROGRESS) {
      description = (
        <>
          {t("program_time_to_complete_short", {
            time: formatTimeCountdown(taskDetails?.availableDateEnd)
          })}
        </>
      );
    }

    setDetails({
      title: selectedModule.title,
      description,
      to: `/app/program/${slug}/${selectedModule.taskReference}`,
      status: selectedModule.status
    });
  }, [selectedModule, props]);

  return (
    <>
      <TaskItem {...taskProps} />
    </>
  );
};

export default ProgramTaskItem;
