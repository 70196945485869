import type { PurchaseItemResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo, useRef } from "react";
import { APP_BREAKPOINT } from "src/constants/layout";
import {
  METADATA_DAILY_DOSAGE_TIMES,
  METADATA_NEW_MEDS_KEY,
  METADATA_RAMPING_UP_DOSAGE
} from "src/constants/misc";
import { checkItemType } from "src/lib/checkItemType";
import getPillsPerDay from "src/lib/getPillsPerDay";
import translate from "src/lib/translate";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import {
  ItemType,
  SubscriptionInterval
} from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import { useBloc } from "src/state/state";
import Price from "src/ui/components/Price/Price";
import { PurchaseItemTitle } from "src/ui/components/PurchaseItemTitle/PurchaseItemTitle";
import Translate from "src/ui/components/Translate/Translate";

const InlineWarning = styled.span`
  color: var(--color-error);
`;

const TextItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 0.75rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    flex-direction: column;
    gap: 0.125rem;
  }
`;

const MedicationRowItemContainer = styled.div`
  display: flex;
  padding: 1rem;
  --justifyContent: flex-start;

  body &:active {
    background-color: #0000000a;
  }
  body &:hover {
    text-decoration: none;
  }

  @media screen and (min-width: ${APP_BREAKPOINT}px) {
    padding: 1.4rem;
    flex-direction: row;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .image {
    border-radius: 8px;
    background-color: #f2efe7;
    overflow: hidden;
    height: 54px;
    width: 54px;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

interface Props {
  item: PurchaseItemResponse;
  duration?: SubscriptionInterval;
  onRemove?: (selectedItem: PurchaseItemResponse) => void;
  hideDetails?: boolean;
  image?: string;
  imageAlt?: string;
  hideIfRemoved?: boolean;
  description?: string;
  inlineWarning?: string;
  showRefill?: boolean;
  sku?: string;
  hideRemove?: boolean;
  hidePrice?: boolean;
}

export const MedicationRowItem: FC<Props> = ({
  item,
  hideDetails,
  onRemove,
  image,
  imageAlt,
  hideIfRemoved,
  description,
  inlineWarning,
  showRefill = false,
  hideRemove,
  hidePrice
}) => {
  const medItem = useRef<HTMLDivElement>(null);
  const [{ hiddenItems }] = useBloc(SubscriptionCubit);
  const isHidden = hiddenItems?.includes(item.id);

  const isNewMed = item.metadata[METADATA_NEW_MEDS_KEY] === true;
  const dailyDosageTimes = item.metadata[METADATA_DAILY_DOSAGE_TIMES] as
    | number
    | undefined;

  const isRampUp = Boolean(
    Boolean(item.metadata[METADATA_RAMPING_UP_DOSAGE]) && dailyDosageTimes
  );

  const isPlaceholderMed = checkItemType(
    item.externalItemType,
    ItemType.PRELIMINARY_MEDICATION_SUGGESTION
  );

  const descriptionText = useMemo(() => {
    if (description) {
      return description;
    }

    if (isPlaceholderMed) {
      return translate("medication_description_placeholder_medication_dosage");
    }

    if (isRampUp)
      return translate(
        isNewMed ? "rampup_new_on_meds" : "rampup_existing_meds"
      );

    return getPillsPerDay(item);
  }, [item, isRampUp, isNewMed]);

  useEffect(() => {
    const node = medItem.current;
    if (hideIfRemoved && isHidden && node) {
      node.style.height = `${node.clientHeight}px`;

      requestAnimationFrame(() => {
        node.style.height = "0px";
        node.style.opacity = "0.5";
      });
    }
  }, [isHidden, hideIfRemoved]);

  const ndc = item.metadata["medication.ndc"] as string;

  return (
    <MedicationRowItemContainer ref={medItem} className="link">
      {image && (
        <div className="image">
          <img src={image} alt={imageAlt} />
        </div>
      )}
      <TextItemsContainer>
        <div>
          <h3 className="as-body2 m0 strong">
            <PurchaseItemTitle
              text={item.displayName}
              ndc={ndc}
              isNewMed={isNewMed}
              isRampUp={isRampUp}
            />
          </h3>

          {!hideDetails && (
            <p className="m0 color-c-80 as-little">
              {descriptionText}
              {inlineWarning && (
                <>
                  <br />
                  <InlineWarning>{inlineWarning}</InlineWarning>
                </>
              )}
            </p>
          )}
        </div>

        <ItemsContainer>
          {showRefill && (
            <nine-pill>
              <Translate msg="autoRefill" />
            </nine-pill>
          )}

          {!hidePrice && (
            <p className="m0 color-c-60">
              <Price
                amount={item.price}
                duration={SubscriptionInterval.monthly}
              />
            </p>
          )}

          {!hideRemove && onRemove && (
            <button
              className="flex items-center inline-button"
              onClick={(): void => onRemove(item)}
            >
              <div className="inline-button color-gray as-little strong hover-underline">
                <Translate msg="remove" />
              </div>
            </button>
          )}
        </ItemsContainer>
      </TextItemsContainer>
    </MedicationRowItemContainer>
  );
};
