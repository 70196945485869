import { GetLabOrdersResponseItem } from "@9amhealth/openapi";
import type { FC } from "react";
import React, { useCallback, useMemo } from "react";
import { MEDICAL_ASSESSMENT_ID } from "src/constants/misc";
import Path from "src/constants/path";
import translate from "src/lib/translate";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import useLoading from "src/state/hooks/useLoading";
import { useBloc } from "src/state/state";
import type { StatusButtonProps } from "src/ui/components/StatusList/StatusList";
import StatusList, {
  StepStatus
} from "src/ui/components/StatusList/StatusList";

const LabStatusList: FC<{
  medicalDataCollected: boolean;
  orderStatus?: GetLabOrdersResponseItem.status;
  trackingUrl?: string;
  buttonLink?: string;
  buttonText?: string;
  noWrapper?: boolean;
  isHomePhlebotomy?: boolean;
  title?: string;
  subtitle?: string;
  icon?: string;
}> = (props) => {
  const loading = useLoading(undefined, false);

  const [, { a1cSubscriptions }] = useBloc(SubscriptionCubit);

  const resultsAvailable = useMemo(() => {
    return (
      props.orderStatus === GetLabOrdersResponseItem.status.RESULTS_AVAILABLE
    );
  }, [props]);

  const shippingA1cTest = useMemo(() => {
    return (
      props.orderStatus ===
        GetLabOrdersResponseItem.status.SHIPMENT_INITIATED ||
      props.orderStatus === GetLabOrdersResponseItem.status.SHIPPING_TO_PATIENT
    );
  }, [props]);

  const testWasReturned = useMemo(() => {
    return (
      props.orderStatus === GetLabOrdersResponseItem.status.KIT_SENT_TO_LAB ||
      props.orderStatus === GetLabOrdersResponseItem.status.KIT_DELIVERED_TO_LAB
    );
  }, [props]);

  const testWasCompleted = useMemo(() => {
    return (
      props.orderStatus === GetLabOrdersResponseItem.status.COMPLETED ||
      props.orderStatus === GetLabOrdersResponseItem.status.DECLINED ||
      (!props.isHomePhlebotomy &&
        props.orderStatus === GetLabOrdersResponseItem.status.RESULTS_AVAILABLE)
    );
  }, [props]);

  const userReceivedTest = useMemo(() => {
    return (
      props.orderStatus === GetLabOrdersResponseItem.status.DELIVERED_TO_PATIENT
    );
  }, [props]);

  const renderStatusList = !testWasCompleted && a1cSubscriptions.length > 0;

  const a1cStatusButtonProps: StatusButtonProps = useMemo(() => {
    if (props.buttonLink && props.buttonText) {
      return {
        link: props.buttonLink,
        label: props.buttonText
      };
    }

    let label = translate("watchTutorial");
    let link: string | { pathname: string } = `#labinstructions`;

    if (!props.medicalDataCollected) {
      label = translate("startAssessment");
      link = `/app/questionnaire/${MEDICAL_ASSESSMENT_ID}`;
    } else if (shippingA1cTest && props.trackingUrl) {
      label = translate("trackShipment");
      link = { pathname: props.trackingUrl };
    } else if (testWasReturned) {
      label = translate("messageCareTeam");
      link = Path.chat;
    }

    return {
      label,
      link
    };
  }, [
    props,
    shippingA1cTest,
    testWasReturned,
    props.buttonLink,
    props.buttonText
  ]);

  const shippingStatus = useMemo(() => {
    if (shippingA1cTest) return 1;
    if (userReceivedTest) return 2;
    if (testWasReturned) return 3;
    if (testWasCompleted) return 3;

    // home phlebotomy statuses
    if (resultsAvailable) return 1;

    return 0;
  }, [props.orderStatus]);

  const evalStep = useCallback(
    (step: number): StepStatus =>
      shippingStatus < step
        ? StepStatus.toDo
        : shippingStatus === step
          ? StepStatus.inProgress
          : StepStatus.done,
    [shippingStatus]
  );

  const a1cBloodSpotSteps = [
    { status: StepStatus.done, title: translate("lab.task.orderLabTest") },
    {
      status: props.medicalDataCollected
        ? StepStatus.done
        : StepStatus.inProgress,
      title: translate("lab.task.completeMedicalAssessment")
    },
    {
      status: props.medicalDataCollected
        ? StepStatus.inProgress
        : StepStatus.toDo,
      title: translate("lab.task.meetDiabetesSpecialist")
    },
    {
      status: evalStep(1),
      title: translate("lab.task.visitLab")
    }
  ];

  const a1cHomePhlebotomySteps = [
    { status: StepStatus.done, title: translate("lab.task.orderLabTest") },
    {
      status: props.medicalDataCollected
        ? StepStatus.done
        : StepStatus.inProgress,
      title: translate("lab.task.completeMedicalAssessment")
    },
    {
      status: props.medicalDataCollected
        ? StepStatus.inProgress
        : StepStatus.toDo,
      title: translate("lab.task.meetDiabetesSpecialist")
    },
    {
      status: evalStep(1),
      title: translate("lab.task.scheduleLab")
    }
  ];

  const steps = props.isHomePhlebotomy
    ? a1cHomePhlebotomySteps
    : a1cBloodSpotSteps;

  let title = translate(
    props.medicalDataCollected
      ? "lab.task.meetDiabetesSpecialist"
      : "lab.task.completeMedicalAssessment"
  );
  let subtitle = translate("lab.result.info");
  let icon = props.medicalDataCollected ? "dcs" : "assessment";

  if (props.title) {
    // eslint-disable-next-line @typescript-eslint/prefer-destructuring
    title = props.title;
  }
  if (props.subtitle) {
    // eslint-disable-next-line @typescript-eslint/prefer-destructuring
    subtitle = props.subtitle;
  }
  if (props.icon) {
    // eslint-disable-next-line @typescript-eslint/prefer-destructuring
    icon = props.icon;
  }

  if (!loading && renderStatusList) {
    return (
      <StatusList
        title={title}
        subtitle={subtitle}
        icon={icon}
        noWrapper={props.noWrapper}
        titleAffix={translate("status.inProgress")}
        steps={steps}
        buttonProps={a1cStatusButtonProps}
      />
    );
  }

  return null;
};

export default LabStatusList;
