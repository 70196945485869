import type { ShipmentResponse } from "@9amhealth/openapi";
import { ShipmentControllerService } from "@9amhealth/openapi";
import { Cubit } from "blac";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { loadingState } from "src/state/state";

export interface ShipmentState {
  shipments: ShipmentResponse[];
}

export default class ShipmentCubit extends Cubit<ShipmentState> {
  constructor() {
    super({
      shipments: []
    });
  }

  public readonly loadAllShipments = async (): Promise<void> => {
    loadingState.start(LoadingKey.shipment);

    try {
      const {
        data: { shipments }
      } = await ShipmentControllerService.allShipments();

      this.emit({ shipments });
    } catch (e: unknown) {
      reportErrorSentry(e);
    }

    loadingState.finish(LoadingKey.shipment);
  };
}
