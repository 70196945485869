import React from "react";
const IconCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="20"
    fill="none"
    viewBox="0 0 27 20"
  >
    <path
      fill="#fff"
      d="M26.295.64a1.667 1.667 0 0 0-2.34.02L9.375 15.279 3.126 9.032a1.667 1.667 0 0 0-2.337-.02l-.094.092a1.667 1.667 0 0 0-.02 2.376L8.198 19c.65.651 1.705.652 2.356.002L26.447 3.147A1.667 1.667 0 0 0 26.429.77l-.134-.13Z"
    />
  </svg>
);
export default IconCheck;
