import type { FC } from "react";
import React from "react";
import { Wrapper } from "./FullSubscriptionPlanOverviewContent";
import Translate from "src/ui/components/Translate/Translate";
import translate from "src/lib/translate";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import devicesBox from "src/ui/assets/images/medical-devices-box.jpg";
import { ParsedPurchaseItemResponse } from "src/lib/parsePurchaseItem";
import { DeviceItem } from "@9amhealth/openapi";
import TestStripsAndGlucometerImage from "src/ui/assets/testStrips_glucomether_square_new.jpg";
import { getMedicationItemSig } from "src/lib/getMedicationItemSig";
import { MedicationRowItem } from "src/ui/components/MedicationRowItem/MedicationRowItem";
import { deviceTypeToImageMap } from "src/constants/devices";
import { DeviceRowItem } from "src/ui/components/DeviceRowItem/DeviceRowItem";
import CollectionItemElement from "src/ui/components/StyledComponents/CollectionItemElement";

export const SuppliesDevicesSection: FC<{
  showTestStrips: boolean;
  parsedSupplies: ParsedPurchaseItemResponse[];
  storedDevices: DeviceItem[];
  testStripsMeasurementFrequency: number;
  testStripsQuantity?: number;
}> = ({
  showTestStrips,
  parsedSupplies,
  storedDevices,
  testStripsMeasurementFrequency,
  testStripsQuantity
}) => {
  const makeDescription = (stripFrequency: number, stripsQuantity?: number) =>
    `${translate("plan_overview_test_strips_description", {
      count: stripFrequency
    })} ${translate("plan_overview_test_strips_title", {
      quantity: stripsQuantity
    })}.`;

  return (
    <Wrapper>
      <CollectionTitle>
        <Translate msg="devicesAndSupplies" />
      </CollectionTitle>

      <Collection>
        {parsedSupplies.map((item) => {
          const inlineWarning = item.expiredAt
            ? translate("prescription_valid_missing_lab_values_alert")
            : "";

          const sig = getMedicationItemSig(item);

          return (
            <MedicationRowItem
              key={item.sku}
              sku={item.sku}
              item={item}
              image={devicesBox}
              description={sig}
              inlineWarning={inlineWarning}
              hidePrice
              hideRemove
            />
          );
        })}
        {storedDevices.map((device) => {
          const deviceConfig = deviceTypeToImageMap[device.type];
          if (deviceConfig === null) {
            return null;
          }
          return (
            <DeviceRowItem
              key={device.id}
              device={device}
              image={deviceConfig.src}
              imageAlt={deviceConfig.alt}
            />
          );
        })}
        {showTestStrips && (
          <CollectionItemElement
            imageSrc={TestStripsAndGlucometerImage}
            imageAlt={translate("image.alt.contourNextOne")}
            label={translate("planOverview.contourTestKit")}
            status={
              <div className="m0">
                {makeDescription(
                  testStripsMeasurementFrequency,
                  testStripsQuantity
                )}
              </div>
            }
            card={true}
            refill={true}
          />
        )}
      </Collection>
    </Wrapper>
  );
};
