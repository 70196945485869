import type { FC } from "react";
import React from "react";

const PlayIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width="32"
    height="32"
    fill="none"
  >
    <path
      fill="url(#a)"
      fillRule="evenodd"
      d="M16 30.599c8.063 0 14.599-6.536 14.599-14.599S24.063 1.401 16 1.401 1.401 7.937 1.401 16 7.937 30.599 16 30.599ZM16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Z"
      clipRule="evenodd"
    />
    <path
      fill="url(#b)"
      d="M30.599 16c0 8.063-6.536 14.599-14.599 14.599S1.401 24.063 1.401 16 7.937 1.401 16 1.401 30.599 7.937 30.599 16Z"
    />
    <path
      fill="#212121"
      d="m20.373 16.168-6.663 4.268a.2.2 0 0 1-.308-.169v-8.535a.2.2 0 0 1 .308-.168l6.663 4.267a.2.2 0 0 1 0 .337Z"
    />
    <defs>
      <radialGradient
        id="a"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(94.403 8.308 9.547) scale(32.1051)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFBD70" />
        <stop offset=".527" stopColor="#F7BDE6" />
        <stop offset="1" stopColor="#80AEFF" />
      </radialGradient>
      <radialGradient
        id="b"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(94.403 8.308 9.547) scale(32.1051)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFBD70" />
        <stop offset=".527" stopColor="#F7BDE6" />
        <stop offset="1" stopColor="#80AEFF" />
      </radialGradient>
    </defs>
  </svg>
);

export default PlayIcon;
