import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { IconLock, IconPlayArrow } from "src/constants/icons";
import type { ProgramModuleItem } from "src/state/ProgramBloc/ProgramBloc";
import ProgramBloc from "src/state/ProgramBloc/ProgramBloc";
import ModuleItemStatusDetails from "src/ui/components/ModuleItemSmall/ModuleItemStatusDetails";
import ResponsiveImage from "src/ui/components/ResponsiveImage/ResponsiveImage";

const Wrap = styled.div`
  display: flex;
  padding: 0 0 1em 0;
  align-items: flex-start;
  gap: 1em;
  align-self: stretch;
  position: relative;

  /* border after */
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: var(--greys-light-gray, #e6e3db);
  }

  &:last-child:after {
    display: none;
  }

  --strong-color: var(--color-charcoal-60);

  &[data-status="${TaskResponse.status.AVAILABLE}"],
  &[data-status="${TaskResponse.status.IN_PROGRESS}"] {
    --strong-color: var(--color-charcoal);
  }
`;

const Media = styled.button`
  width: 5rem;
  height: 5rem;
  position: relative;
  outline-color: transparent;
  pointer-events: none;

  #attention {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    z-index: 3;
    display: none;
  }

  *[data-status="${TaskResponse.status.LOCKED}"] & {
    filter: grayscale(70%);
    opacity: 0.8;
  }

  *[data-status="${TaskResponse.status.COMPLETED}"] &,
  *[data-status="${TaskResponse.status.SKIPPED}"] & {
    opacity: 0.5;
    cursor: pointer;
    pointer-events: all;
  }

  *[data-status="${TaskResponse.status.AVAILABLE}"] &,
  *[data-status="${TaskResponse.status.IN_PROGRESS}"] & {
    opacity: 1;
    cursor: pointer;
    pointer-events: all;

    #attention {
      display: block;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  letter-spacing: -0.02rem;
`;

const Title = styled.div`
  font-weight: 500;
  color: var(--strong-color, inherit);
`;

const Index = styled.span`
  opacity: 0.6;
  padding-right: 0.3em;
`;

const Description = styled.p`
  body & {
    opacity: 0.7;
    margin: 0.5rem 0 0;
  }
`;

const Icon = styled.div`
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  z-index: 2;
  color: white;
`;

const ActionIconBall = styled.div`
  background: var(--primary-error-red, #bf1902);
  width: 1.1875em;
  height: 1.1875em;
  border-radius: 50%;
`;

const ModuleItemSmall: FC<{
  module: ProgramModuleItem;
  onClick?: (module: ProgramModuleItem) => void;
}> = ({ module, onClick }) => {
  const { details } = ProgramBloc.getModuleTaskRepresentative(module) ?? {};

  const { title } = module;

  const MediaIcon = () => {
    switch (module.status) {
      case TaskResponse.status.COMPLETED:
      case TaskResponse.status.IN_PROGRESS:
      case TaskResponse.status.AVAILABLE:
      case TaskResponse.status.SKIPPED:
        return <IconPlayArrow />;
      case TaskResponse.status.LOCKED:
        return <IconLock />;
      default:
        return null;
    }
  };

  return (
    <Wrap data-status={module.status}>
      <Media onClick={() => onClick?.(module)}>
        <ResponsiveImage fillContainer image={module.image} startWidth={80} />
        <Icon>
          <MediaIcon />
        </Icon>
        <ActionIconBall id="attention" />
      </Media>
      <Content>
        <Title>
          <Index>{module.moduleNumber}.</Index>
          {title}
        </Title>
        <Description>{module.description}</Description>
        <ModuleItemStatusDetails status={module.status} taskDetails={details} />
      </Content>
    </Wrap>
  );
};

export default ModuleItemSmall;
