import type { FC } from "react";
import React from "react";
import { PurchaseItemResponse } from "@9amhealth/openapi";
import { LabInfo, Wrapper } from "./FullSubscriptionPlanOverviewContent";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import Translate from "src/ui/components/Translate/Translate";
import translate from "src/lib/translate";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";

export const AccessToCareSection: FC<{
  labInfo?: LabInfo;
  showAccessToCareTeam?: PurchaseItemResponse;
}> = ({ labInfo, showAccessToCareTeam }) => {
  return (
    <Wrapper>
      <CollectionTitle>
        <Translate msg="medicalCare" />
      </CollectionTitle>
      <Collection>
        {labInfo && (
          <CollectionItemLink
            onClick={() =>
              AppQueryPopupsController.openPopup(AppPopup.sanityContent, {
                additionalParameters: {
                  slug: labInfo.slug
                }
              })
            }
            imageSrc={labInfo.imageSrc}
            imageAlt={labInfo.imageAlt}
            label={translate(labInfo.title)}
            card={true}
            status={translate(labInfo.description)}
          />
        )}
        {showAccessToCareTeam && (
          <>
            <CollectionItemLink
              imageAlt={translate("image.alt.personHoldingPillBottle")}
              imageSrc="https://cdn.sanity.io/images/g38rxyoc/production/f3d0a27a816ca54b4a565fe340a8ba0c3789b5a2-1125x750.jpg"
              label={translate("suggestion_item_title_base_membership")}
              card={true}
              status={translate("planOverview.benefit")}
              onClick={() =>
                AppQueryPopupsController.openPopup(AppPopup.sanityContent, {
                  additionalParameters: {
                    slug: "CARE_TEAM"
                  }
                })
              }
            ></CollectionItemLink>
          </>
        )}
      </Collection>
    </Wrapper>
  );
};
