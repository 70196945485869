import type { FC } from "react";
import React, { useEffect } from "react";
import { featureFlags } from "src/lib/featureFlags";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import UserCubit from "src/state/UserCubit/UserCubit";
import { useBloc } from "src/state/state";
import { FullSubscriptionPlanOverviewContent } from "src/ui/components/PlanOverview/PlanOverviewContent/FullSubscriptionPlanOverviewContent/FullSubscriptionPlanOverviewContent";
import Translate from "src/ui/components/Translate/Translate";

export const PlanOverview: FC = () => {
  const [, { loadUserAnsweredQuestionnaires }] = useBloc(UserCubit);
  const [{ allSubscriptions = [] }, { loadAllSubscriptions }] =
    useBloc(SubscriptionCubit);

  useEffect(() => {
    void loadAllSubscriptions();
    void loadUserAnsweredQuestionnaires();
  }, []);

  if (allSubscriptions.length === 0 && featureFlags.appAccess) {
    return (
      <nine-center>
        <Translate msg="preview.notAvailable" />
      </nine-center>
    );
  }

  return <FullSubscriptionPlanOverviewContent />;
};

export default PlanOverview;
