import { Button } from "@9amhealth/shared";
import { useBloc } from "@blac/react";
import type { FC } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IconWorkInProgress } from "src/constants/icons";
import Translate from "src/ui/components/Translate/Translate";
import ChartNotAvailableOverlay from "./ChartNotAvailableOverlay";
import DataHubCubit from "./DataHubCubit";

const ChartComingSoon: FC = () => {
  const [{ activeLoincCode }, { getLoincCodesForUrl }] = useBloc(DataHubCubit);
  const navigate = useNavigate();

  return (
    <ChartNotAvailableOverlay
      before={<IconWorkInProgress />}
      title={<Translate msg="comingSoon" />}
      description={<Translate msg="graphUnderConstruction" />}
      after={
        <Button
          onPress={() => {
            navigate(
              `/app/lab-results/list-codes/${getLoincCodesForUrl(activeLoincCode)}`
            );
          }}
        >
          <Translate msg="exploreData" />
        </Button>
      }
    />
  );
};

export default ChartComingSoon;
