import styled from "@emotion/styled";
import { APP_BREAKPOINT_SM } from "src/constants/layout";

export const OpacityGradient = styled.div<{ elementsCount: number }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: var(--spaceWidth);
  left: calc(calc(var(--spaceWidth) * -1) - 1px);
  z-index: ${({ elementsCount }) => elementsCount + 1};
  background: linear-gradient(
    to right,
    var(--color-cream),
    rgba(255, 255, 255, 0)
  );

  &:first-of-type {
    left: unset;
    right: calc(var(--spaceWidth) * -1);
    background: linear-gradient(
      to left,
      var(--color-cream),
      rgba(255, 255, 255, 0)
    );
  }

  @media screen and (max-width: ${APP_BREAKPOINT_SM}px) {
    display: none;
  }
`;
