import styled from "@emotion/styled";

const AppTopSpacer = styled.div<{
  pt?: boolean;
}>`
  padding-top: ${(props) => (props.pt ? "1rem" : 0)};
  height: var(--ion-safe-area-top, 0px);
`;

export default AppTopSpacer;
