import { useBloc } from "@blac/react";
import type { FC } from "react";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import translate from "src/lib/translate";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import AppSubPage from "src/ui/components/AppSubPage/AppSubPage";
import CustomSuspense from "src/ui/components/CustomSuspense/CustomSuspense";
import LabResults from "src/ui/components/LabResults/LabResults";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import DataHubCubit from "../DataHub/DataHubCubit";

const Async = {
  ListLabsByCodes: React.lazy(
    async () => import("src/ui/components/ListLabsByCodes/ListLabsByCodes")
  ),
  ListLabsFromBundle: React.lazy(
    async () =>
      import("src/ui/components/ListLabsFromBundle/ListLabsFromBundle")
  )
};

export const LABS_BASE_PAGE_URL = "/app/lab-results";
const LabResultsDataSubPageRouter: FC = () => {
  const [, { clearApiCache }] = useBloc(DataHubCubit);

  useEffect(() => {
    // Clear cache when unmounting
    return () => clearApiCache();
  }, []);

  return (
    <Routes>
      <Route
        path={`/list-codes/:codes`}
        element={
          <AppSubPage>
            <OverlayBackButton show fallbackUrl={LABS_BASE_PAGE_URL} />
            <AppPageTitle title={translate("recordedData")}></AppPageTitle>
            <nine-spacer s={"sm"}></nine-spacer>
            <CustomSuspense>
              <Async.ListLabsByCodes />
            </CustomSuspense>
          </AppSubPage>
        }
      />

      <Route
        path={`/bundle`}
        element={
          <AppSubPage>
            <nine-spacer s="sm"></nine-spacer>
            <CustomSuspense>
              <Async.ListLabsFromBundle />
            </CustomSuspense>
          </AppSubPage>
        }
      />

      <Route
        path={"*"}
        element={
          <>
            <LabResults />
          </>
        }
      />
    </Routes>
  );
};

export default LabResultsDataSubPageRouter;
