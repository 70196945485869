import { useEffect, useState } from "react";
import type { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import LoadingCubit from "src/state/LoadingCubit/LoadingCubit";
import { useBloc } from "src/state/state";

/**
 * Hook to track loading state, initial state is set until the loadingCubit says it changed from the initial state
 * @param key LoadingKey, from LoadingCubit
 * @param initial Initial loading state, only changes after LoadingCubit state matches the initial, then changes
 * @returns
 */
export default function useLoading(key?: LoadingKey, initial = true): boolean {
  const [, { isLoading }] = useBloc(LoadingCubit);
  const [loading, setLoading] = useState(initial);
  const [disableInitial, setDisableInitial] = useState(false);

  const stateLoading = isLoading(key);

  useEffect(() => {
    if (disableInitial) {
      setLoading(stateLoading);
    } else if (loading === stateLoading) {
      setDisableInitial(true);
    }
  }, [stateLoading]);

  return loading;
}
