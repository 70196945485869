import type { FC } from "react";
import React from "react";
import { LoadingSpinner } from "../BlockingLoadingOverlay/BlockingLoadingOverlay";
import ChartNotAvailableOverlay from "./ChartNotAvailableOverlay";

const ChartLoadingState: FC = () => {
  return (
    <ChartNotAvailableOverlay>
      <LoadingSpinner />
    </ChartNotAvailableOverlay>
  );
};

export default ChartLoadingState;
