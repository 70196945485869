export enum OrderedLabSkus {
  A1C = "A1C",
  ALANINE_TRANSAMINASE = "ALANINE_TRANSAMINASE",
  ASPARTATE_AMINOTRANSFERASE = "ASPARTATE_AMINOTRANSFERASE",
  BMP = "BMP",
  C_PEPTIDE_SERUM = "C_PEPTIDE_SERUM",
  CMP = "CMP",
  CREATININE = "CREATININE",
  GAD65 = "GAD65",
  GLUCOSE = "GLUCOSE",
  LIPIDS = "LIPIDS",
  POTASSIUM = "POTASSIUM",
  SODIUM = "SODIUM",
  THYROID_STIMULATING_HORMONE = "THYROID_STIMULATING_HORMONE",
  URINARY_ALBUM_TO_CREATININE = "URINARY_ALBUM_TO_CREATININE"
}
