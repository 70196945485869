import React, { FC } from "react";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { useBloc } from "src/state/state";
import styled from "@emotion/styled";
import Logo from "src/ui/assets/icons/Logo";

const Position = styled.div`
  font-size: 0.82rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  display: flex;
  align-items: baseline;
  margin-top: -2.9rem;
  margin-bottom: 1.8rem;

  svg {
    width: auto;
    height: 1em;
    margin-left: 0.3em;
    display: inline-block;
    position: relative;
    top: 0.084em;
  }
`;

const PoweredByWidget: FC = () => {
  const [{ partnerSession }] = useBloc(AppViewCubit);
  if (!partnerSession) return null;
  return (
    <Position>
      <span>Powered by</span> <Logo />
    </Position>
  );
};

export default PoweredByWidget;
