export const diabetesMedications: string[] = [
  "metformin",
  "metformin_ER",
  "pioglitazone",
  "glipizide_xl",
  "glipizide",
  "glimepiride",
  "pioglitzone"
];

export const bloodPressureMedications: string[] = [
  "lisinopril",
  "losartan",
  "hydrochlorothiazide",
  "amlodipine"
];

export const cholesterolMedications: string[] = [
  "atorvastatin",
  "lovastatin",
  "lovaza",
  "gemfibrozil",
  "rosuvastatin",
  "simvastatin"
];

export const medicationNdcs: Record<string, string[]> = {
  metformin: [
    "70010-063-10",
    "70010-064-01",
    "70010-065-10",
    "70010-491-10",
    "51224-107-50"
  ],
  glipizide: [
    "60505-141-01",
    "60505-142-01",
    "16714-894-01",
    "16714-895-01",
    "16714-896-01"
  ],
  glimepiride: ["55111-320-01", "55111-321-01", "55111-322-05"],
  pioglitazone: ["57237-219-05", "57237-220-05", "16729-022-15"],
  lisinopril: [
    "43547-351-50",
    "43547-352-11",
    "43547-353-11",
    "43547-354-11",
    "43547-355-50",
    "43547-356-11"
  ],
  losartan: ["68180-376-09", "68382-136-10", "65862-203-99"],
  hydrochlorothiazide: ["16729-182-17", "29300-128-10", "16729-184-01"],
  amlodipine: ["67877-197-10", "67877-198-05", "67877-199-05"],
  lovaza: ["42806-552-12"],
  gemfibrozil: ["16714-101-02"],
  atorvastatin: [
    "63304-827-05",
    "63304-828-05",
    "63304-829-05",
    "63304-830-05"
  ],
  rosuvastatin: [
    "57237-168-05",
    "67877-440-05",
    "72205-004-99",
    "27808-158-03"
  ],
  lovastatin: ["68180-467-01", "68180-468-01", "68180-469-01"],
  simvastatin: [
    "16714-681-02",
    "16714-682-03",
    "16714-683-03",
    "16714-684-03",
    "16714-685-02"
  ]
};
