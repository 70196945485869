import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { FC } from "react";
import UserJourneyBloc, {
  UserJourneyLesson,
  UserJourneyLessonItem,
  UserJourneyModule
} from "./UserJourneyBloc";
import { TaskResponse, UpdateTaskRequest } from "@9amhealth/openapi";
import {
  ButtonVisual,
  VisualIcon,
  VisualOverlay,
  VisualText
} from "./ModuleButtonComponents";
import Spacer from "./Spacer";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import translate from "src/lib/translate";
import { Button } from "react-aria-components";
import { taskManagementState, toast } from "src/state/state";
import {
  AppPopup,
  useAppQueryPopup
} from "../AppQueryPopups/AppQueryPopupsBloc";
import reportErrorSentry from "src/lib/reportErrorSentry";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { useSearchParams } from "react-router-dom";
import { useBloc } from "@blac/react";
import { dateLocal } from "src/lib/date";
import { getSupportedUserLanguage } from "src/lib/i18next";
import leftPadNumber from "src/lib/leftPadNumber";

const wiggleLocked = keyframes`
  0% { transform: translateX(0); }
  15% { transform: translateX(0.66rem); }
  30% { transform: translateX(-0.5rem); }
  45% { transform: translateX(0.44rem); }
  60% { transform: translateX(-0.32rem); }
  75% { transform: translateX(0.3rem); }
  90% { transform: translateX(-0.1rem); }
  100% { transform: translateX(0); }
`;

const openingScaleUp = keyframes`
  0% { transform: scale(1); }
  40% { opacity: 0; }
  50% {
    transform: scale(2);
    opacity: 0;
  }
  90% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const ButtonWrap = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: transparent;
  border: none;
  width: 100%;
  outline: none;

  &[transition="LOCKED"] {
    .button-background {
      animation: ${wiggleLocked} 0.8s ease-in-out;
    }
  }

  &[transition="AVAILABLE"],
  &[transition="COMPLETED"],
  &[transition="IN_PROGRESS"],
  &[transition="SKIPPED"] {
    .button-background {
      animation: ${openingScaleUp} 1.2s ease-in-out;
    }
  }

  .module-list-item.last .lesson-list-item.last &:last-child {
    .spacer {
      display: none;
    }
  }
`;

const ModuleName = styled.div`
  margin-top: 0.5rem;
  color: var(--Greys-Charcoal, #212121);
  text-align: center;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.0225rem;
  padding: 0 1.5rem;
  line-height: 1.4;
  text-wrap: pretty;
`;

const ModuleStatus = styled.div``;

const JourneyModuleTrigger: FC<{
  item: UserJourneyLessonItem;
  lesson: UserJourneyLesson;
  module: UserJourneyModule;
  onTaskStatusUpdated: (taskStatus: string) => void;
}> = (props) => {
  const [{ enableAll }, { tasks, questionnaireTasks }] =
    useBloc(UserJourneyBloc);
  const { item, lesson, module, onTaskStatusUpdated } = props;
  const refButton = useRef<HTMLButtonElement>(null);
  const questionnaireIndex = questionnaireTasks.findIndex(
    (q) => q === item.task
  );
  const isQuestionnaire = item.type === "typeform";
  const isLocked = item.task?.status === TaskResponse.status.LOCKED;
  const visualText = isQuestionnaire
    ? leftPadNumber(questionnaireIndex + 1)
    : "";
  const [searchParams] = useSearchParams();
  const groupParam = searchParams.get("group");
  const slugParam = searchParams.get("slug");

  const animateSpacerAfter = useMemo(() => {
    const slug = item.task?.slug;
    if (!slug) return false;

    const taskIndex = tasks.findIndex((t) => t?.slug === slug);
    if (taskIndex === -1) return false;

    const nextTask = tasks[taskIndex + 1];

    if (!nextTask) return false;
    const nextTaskStatus = nextTask.status;
    const nextTaskIsCoaching = nextTask.slug.includes("coaching");

    return (
      nextTaskStatus !== TaskResponse.status.LOCKED ||
      (!isLocked && nextTaskIsCoaching)
    );
  }, [item.task?.slug]);

  useEffect(() => {
    // scroll to button if available or in progress
    const status = item.task?.status;
    const allowedStatuses = [TaskResponse.status.AVAILABLE];
    if (isQuestionnaire) {
      allowedStatuses.push(TaskResponse.status.IN_PROGRESS);
    }
    if (status && allowedStatuses.includes(status) && refButton.current) {
      refButton.current.scrollIntoView({
        behavior: "instant",
        block: "center"
      });
    }
  }, [item.task?.status, refButton.current]);

  const setTaskStatus = useCallback(
    (status: string) => {
      void taskManagementState.updateTaskStatus(
        {
          program: KnownProgram.LIFEBALANCE,
          group: lesson.taskGroup,
          slug: item.task?.slug
        },
        status as UpdateTaskRequest.status
      );
      onTaskStatusUpdated(status);
    },
    [item.task?.slug, module.group]
  );

  const [openTypeformPopup, closeTypeformPopup] = useAppQueryPopup(
    AppPopup.lifestyleProgram
  );
  const [openCoachingPopup] = useAppQueryPopup(AppPopup.appointmentBooking);

  const onClick = useCallback(() => {
    if (!item.task) {
      reportErrorSentry(new Error("Lesson item task is not defined"));
      toast.error("error.generic.short.tryAgain");
      return;
    }
    let triggerButtonAnimation = false;

    const blockedStatuses: TaskResponse.status[] = [TaskResponse.status.LOCKED];
    const statusHasAction = !blockedStatuses.includes(item.task.status);

    if (!statusHasAction && !enableAll) {
      return;
    }

    if (item.type === "typeform") {
      const { typeformId } = item;
      if (!typeformId) {
        reportErrorSentry(new Error("Typeform ID is required"));
        toast.error("error.generic.short.tryAgain");
        return;
      }

      if (
        enableAll ||
        [
          TaskResponse.status.AVAILABLE,
          TaskResponse.status.IN_PROGRESS,
          TaskResponse.status.COMPLETED,
          TaskResponse.status.SKIPPED
        ].includes(item.task.status)
      ) {
        triggerButtonAnimation = true;
        openTypeformPopup({
          additionalParameters: {
            stay: "false",
            questionnaireId: typeformId,
            title: lesson.name
          },
          onEvent: {
            nineQuestionnaireStarted: () => {
              if (item.task?.status === TaskResponse.status.AVAILABLE) {
                setTaskStatus(TaskResponse.status.IN_PROGRESS);
              }
            },
            nineQuestionnaireSaved: () => {
              setTaskStatus(TaskResponse.status.COMPLETED);
              closeTypeformPopup();
            }
          }
        });
      }
    }

    if (item.type === "coaching") {
      const { url } = item;
      if (!url) {
        reportErrorSentry(new Error("Coaching URL is required"));
        toast.error("error.generic.short.tryAgain");
        return;
      }

      if (
        enableAll ||
        [TaskResponse.status.AVAILABLE].includes(item.task.status)
      ) {
        triggerButtonAnimation = true;
        openCoachingPopup({
          additionalParameters: {
            stay: "false",
            url,
            title: lesson.name,
            status: item.task.status
          },
          onEvent: {
            "scheduled-appointment": () => {
              toast.success("notification.appointmentScheduled");
              setTaskStatus(TaskResponse.status.IN_PROGRESS);
            },
            "skipped-appointment": () => {
              setTaskStatus(TaskResponse.status.SKIPPED);
            }
          }
        });
      }
    }

    if (triggerButtonAnimation && refButton.current) {
      refButton.current.removeAttribute("transition");
      requestAnimationFrame(() => {
        refButton.current?.setAttribute(
          "transition",
          item.task?.status ?? "none"
        );
      });
    }
  }, [refButton.current, item.task, lesson.name, enableAll]);

  useEffect(() => {
    const { task } = item;
    if (task?.group === groupParam && task.slug === slugParam) {
      onClick();
    }
  }, [groupParam, slugParam, item.task]);

  return (
    <ButtonWrap onPress={onClick} ref={refButton}>
      <ButtonVisual item={item} module={module}>
        <VisualText>{visualText}</VisualText>
        <VisualOverlay />
        <VisualIcon className="button-icon" status={item.task?.status} />
      </ButtonVisual>
      <ModuleName>
        {item.type === "typeform" ? lesson.name : translate("coachingSession")}
      </ModuleName>
      {isQuestionnaire && item.task?.status && (
        <ModuleStatus className="as-little">
          {translate("journey.item.task.status", {
            context: `${item.task.status}${item.task.availableFrom ? "Until" : ""}`,
            date: dateLocal(item.task.availableFrom)
              .toDate()
              .toLocaleString(getSupportedUserLanguage(), {
                month: "numeric",
                day: "numeric",
                year: "numeric"
              })
          })}
        </ModuleStatus>
      )}

      <Spacer animate={animateSpacerAfter} />
    </ButtonWrap>
  );
};

export default JourneyModuleTrigger;
