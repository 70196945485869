import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import translate from "src/lib/translate";
import type { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import ProgramBloc from "src/state/ProgramBloc/ProgramBloc";
import type { TaskResponseKnown } from "src/state/TaskManagementBloc/TaskManagementBloc";
import { taskManagementState, useBloc } from "src/state/state";
import DynamicTaskComponent from "src/ui/components/DynamicTaskComponent/DynamicTaskComponent";
import InDialog from "src/ui/components/InDialog/InDialog";
import Translate from "src/ui/components/Translate/Translate";

const HelpText = styled.p`
  body & {
    margin: 0 auto;
    max-width: 740px;
    text-align: center;
    box-sizing: border-box;
    text-wrap: balance;
    transition: all calc(var(--ease-time) * 0.5) var(--ease-type);
    height: 0;
    padding: 0;
    opacity: 0;

    &[data-show="true"] {
      height: 100px;
      opacity: 1;
      padding: 1.6rem 0.8rem 0.8rem;
    }
  }
`;

const TaskOverlayFooter = styled.div`
  width: 100%;
  padding: 1.6rem 0.8rem 0;
  padding-bottom: calc(var(--ion-safe-area-bottom, 0) + 1em);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all calc(var(--ease-time) * 0.5) var(--ease-type);
  height: 100px;
  margin: 1.5rem 0 0;

  &[data-show="false"] {
    padding: 0;
    height: 0;
    pointer-events: none;
    opacity: 0;
  }
`;

const ProgramModuleDetail: FC<{
  program: KnownProgram;
  module?: string;
  task?: string;
}> = (props) => {
  const [showContinue, setShowContinue] = React.useState(false);
  const [, { selectProgramModule }] = useBloc(ProgramBloc);
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);
  const navigate = useNavigate();
  const selectedModule = selectProgramModule(props.program, [
    {
      taskReference: props.module
    }
  ]);
  const tasks = selectedModule?.tasks ?? [];
  const selectedTask = tasks.find(({ slug }) => slug === props.task);
  const selectedTaskIndex = tasks.findIndex(({ slug }) => slug === props.task);
  const nextTask = tasks[selectedTaskIndex + 1] as
    | TaskResponseKnown
    | undefined;

  const selectedTaskDetails = useMemo(() => {
    const taskDetails = taskManagementState.getTaskDetails(selectedTask);
    const taskIsCompleted =
      selectedTask?.status === TaskResponse.status.COMPLETED;
    const taskIsSkipped = selectedTask?.status === TaskResponse.status.SKIPPED;

    return {
      details: taskDetails,
      completed: taskIsCompleted,
      skipped: taskIsSkipped,
      typeQuestionnaire: taskDetails.type === "questionnaire",
      typeVideo: taskDetails.type === "video",
      openTodo:
        selectedTask?.status === TaskResponse.status.AVAILABLE ||
        selectedTask?.status === TaskResponse.status.IN_PROGRESS
    };
  }, [selectedTask]);

  const nextTaskDetails = useMemo(() => {
    const taskDetails = taskManagementState.getTaskDetails(nextTask);
    const nextTaskCompleted =
      nextTask?.status === TaskResponse.status.COMPLETED;
    const nextTaskSkipped = nextTask?.status === TaskResponse.status.SKIPPED;
    const nextTaskTypeQuestionnaire = taskDetails.type === "questionnaire";

    return {
      details: taskDetails,
      completed: nextTaskCompleted,
      skipped: nextTaskSkipped,
      typeQuestionnaire: nextTaskTypeQuestionnaire
    };
  }, [nextTask]);

  const buttonText = useMemo(() => {
    if (
      (nextTaskDetails.completed || nextTaskDetails.skipped) &&
      nextTaskDetails.typeQuestionnaire
    ) {
      return translate("closeVideo");
    }
    return translate("continueToCheckIn");
  }, [nextTaskDetails]);

  const showVideoHelpText = useMemo(() => {
    if (
      !showContinue &&
      selectedTaskDetails.typeVideo &&
      selectedTaskDetails.openTodo
    ) {
      return true;
    }
  }, [showContinue, selectedTaskDetails]);

  /**
   * Redirect to the first task if the module is specified but not the task
   */
  const redirect = useCallback(() => {
    // do nothing if there is no module
    if (!props.module || !selectedModule) {
      return;
    }

    // navigate to the first task if no task is specified
    const firstTask = selectedModule.tasks?.[0];
    if (!props.task && firstTask) {
      navigate(
        `/app/program/${props.program}/${props.module}/${firstTask.slug}`,
        {
          replace: true
        }
      );
    }
  }, [props, selectedModule]);

  const updateButton = useCallback(() => {
    const { completed, skipped, typeQuestionnaire } = selectedTaskDetails;
    // set the button to show if the task is complete
    if ((completed || skipped) && !typeQuestionnaire) {
      setShowContinue(true);
    }
  }, [selectedTaskDetails]);

  useEffect(() => {
    updateButton();
    redirect();
  }, [props]);

  const handleTaskComplete = () => {
    const finishedLastStep = selectedTaskIndex === tasks.length - 1;
    if (finishedLastStep) {
      void modalRef?.current?.dismiss();
    } else {
      setShowContinue(true);
    }
  };

  const handleClose = () => {
    void modalRef?.current?.dismiss();
  };

  const goToNextTask = () => {
    if (nextTask) {
      const { completed, skipped, typeQuestionnaire } = nextTaskDetails;
      setShowContinue(false);
      if (typeQuestionnaire && (completed || skipped)) {
        return handleClose();
      }
      navigate(
        `/app/program/${props.program}/${props.module}/${nextTask.slug}`
      );
    }
  };

  if (!props.module) return null;
  return (
    <InDialog
      title={`${selectedModule?.moduleNumber}. ${selectedModule?.title}`}
      returnUrl={`/app/program/${props.program}`}
      setRef={setModalRef}
      popup
    >
      <DynamicTaskComponent
        task={selectedTask}
        onComplete={handleTaskComplete}
      />
      <HelpText
        data-show={showVideoHelpText}
        aria-hidden={showVideoHelpText ? "false" : "true"}
      >
        <Translate msg="watchVideo.description" />
      </HelpText>
      <TaskOverlayFooter
        data-show={showContinue}
        aria-hidden={showContinue ? "false" : "true"}
      >
        <nine-button onClick={goToNextTask}>{buttonText}</nine-button>
      </TaskOverlayFooter>
    </InDialog>
  );
};

export default ProgramModuleDetail;
