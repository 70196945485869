import type { FC } from "react";
import React from "react";
import { Wrapper } from "./FullSubscriptionPlanOverviewContent";
import {
  AppPopup,
  AppQueryPopupsController
} from "src/ui/components/AppQueryPopups/AppQueryPopupsBloc";
import CollectionItemLink from "src/ui/components/StyledComponents/CollectionItemLink";
import Translate from "src/ui/components/Translate/Translate";
import translate from "src/lib/translate";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { useNavigate } from "react-router-dom";

export const LifestyleSection: FC<{
  showNutritionProgram: boolean;
  showLifestyleCoaching: boolean;
}> = ({ showNutritionProgram, showLifestyleCoaching }) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <CollectionTitle>
        <Translate msg="lifestyleCoaching" />
      </CollectionTitle>
      <Collection>
        {showNutritionProgram && (
          <CollectionItemLink
            status={translate("rxDiet.myPlan.card.subtitle")}
            label={translate("rxDiet.card.title")}
            card={true}
            onClick={() => {
              AppQueryPopupsController.openPopup(
                AppPopup.rxDietNutritionProgram
              );
            }}
            imageAlt={translate("rxDiet.img.thumbnail.alt")}
            imageSrc="https://cdn.sanity.io/images/g38rxyoc/production/6336712da34b3967fb37d741f735b1535ffea3db-512x512.jpg"
            size="large"
          />
        )}

        {showLifestyleCoaching && (
          <CollectionItemLink
            status={translate("lifestyle.description_lifebalance")}
            label={translate("lifestyle.title_lifebalance")}
            card={true}
            onClick={() => navigate(KnownProgram.LIFEBALANCE)}
            imageAlt={translate("lifestyle.title_lifebalance")}
            imageSrc="https://cdn.sanity.io/images/g38rxyoc/production/23dcd6e8cff08ef0980fc452c97f97e1a6952cff-176x176.png"
            size="large"
          />
        )}
      </Collection>
    </Wrapper>
  );
};
