import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { getReadMoreSlugByNdc } from "src/lib/getReadMoreSlugByNdc";
import { StyledReadMore } from "src/ui/components/ReadMore/ReadMore";

export const TitleWrapper = styled.div`
  display: flex;
`;

interface Props {
  text: string;
  isNewMed: boolean;
  isRampUp: boolean;
  hideReadMore?: boolean;
  ndc?: string;
}

export const PurchaseItemTitle: FC<Props> = ({
  text,
  ndc,
  isNewMed,
  isRampUp,
  hideReadMore
}) => {
  const slug = ndc && getReadMoreSlugByNdc(ndc);

  return (
    <TitleWrapper>
      {text}
      {!hideReadMore && slug && (
        <StyledReadMore
          fetchFromCms={isNewMed && !isRampUp}
          slug={slug}
          height={20}
          width={20}
          fontSize={15}
        />
      )}
    </TitleWrapper>
  );
};
