import type { FC } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import FakeButton from "src/ui/components/FakeButton/FakeButton";
import NextStepsCard, {
  NextStepsCardProps
} from "src/ui/components/NextStepsCard/NextStepsCard";
import Translate from "src/ui/components/Translate/Translate";
import { NineButton } from "@9amhealth/wcl/generated/react";
import translate from "src/lib/translate";
import { MEDICAL_ASSESSMENT_ID } from "src/constants/misc";
import openVcfFile from "src/lib/openVcfFile";
import { Pad } from "src/ui/styled/Pad";

export const NextStepsCardSection: FC<{
  medicalAssessmentActive: boolean;
  hasCompletedInitialConsult: boolean;
  medicalReviewActive: boolean;
}> = ({
  medicalAssessmentActive,
  hasCompletedInitialConsult,
  medicalReviewActive
}) => {
  const navigate = useNavigate();
  const nextStepsCardProps: NextStepsCardProps = {
    steps: [
      { status: "done", title: <Translate msg="task.programEnrollment" /> },
      {
        status: medicalAssessmentActive ? "in-progress" : "done",
        title: <Translate msg="task.medicalAssessment" />,
        subtitle:
          medicalAssessmentActive &&
          translate("task.completeMedicalAssessment"),
        content: medicalAssessmentActive && (
          <NineButton
            onClick={(): void =>
              navigate(`/app/questionnaire/${MEDICAL_ASSESSMENT_ID}`)
            }
          >
            <Translate msg="task.medicalAssessment" />
          </NineButton>
        )
      },
      {
        status: hasCompletedInitialConsult
          ? "done"
          : medicalReviewActive
            ? "in-progress"
            : "to-do",
        title: <Translate msg="task.medicalReview" />,
        subtitle: medicalReviewActive && (
          <>
            <p>
              <Translate msg="task.medicalReview.description" />
            </p>
            <p>
              <Translate msg="notification.savePhoneNumber" />
            </p>
            <FakeButton onClick={() => openVcfFile()}>
              (202) 932-9958
            </FakeButton>
          </>
        )
      },
      { status: "to-do", title: <Translate msg="task.planPersonalization" /> },
      { status: "to-do", title: <Translate msg="task.programStart" /> }
    ]
  };

  return (
    <>
      <Pad>
        <NextStepsCard {...nextStepsCardProps} />
      </Pad>
    </>
  );
};
