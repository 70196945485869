import { UpdateTaskRequest } from "@9amhealth/openapi";
import type { FC } from "react";
import React from "react";
import type { TaskResponseKnown } from "src/state/TaskManagementBloc/TaskManagementBloc";
import { taskManagementState } from "src/state/state";
import QuestionnaireTask from "src/ui/components/DynamicTaskComponent/QuestionnaireTask";
import VideoTask from "src/ui/components/DynamicTaskComponent/VideoTask";

const DynamicTaskComponent: FC<{
  task?: TaskResponseKnown;
  onComplete?: () => void;
}> = ({ task, onComplete }) => {
  if (!task) return null;
  const taskDetails = taskManagementState.getTaskDetails(task);

  const markTaskAsStarted = () => {
    void taskManagementState.updateTaskStatus(
      task,
      UpdateTaskRequest.status.IN_PROGRESS
    );
  };

  const markTaskAsComplete = () => {
    void taskManagementState.updateTaskStatus(
      task,
      UpdateTaskRequest.status.COMPLETED
    );
  };

  switch (taskDetails.type) {
    case "video":
      return (
        <VideoTask
          task={task}
          taskDetails={taskDetails}
          onComplete={() => {
            markTaskAsComplete();
            onComplete?.();
          }}
          onStart={markTaskAsStarted}
        />
      );
    case "questionnaire":
      return (
        <QuestionnaireTask
          task={task}
          taskDetails={taskDetails}
          onClose={onComplete}
          onStart={markTaskAsStarted}
        />
      );
    default:
      return null;
  }
};

export default DynamicTaskComponent;
