import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import ProgramBloc, { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import ProgramPreviewCard from "src/ui/components/ProgramPreviewCard/ProgramPreviewCard";
import Translate from "src/ui/components/Translate/Translate";

const Title = styled.div`
  margin: 1em 0 -1em;
  padding: 0 1.4em;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.02rem;
`;

const ProgramOverview: FC = () => {
  const [state, programBloc] = useBloc(ProgramBloc);

  useEffect(() => {
    void programBloc.updatePrograms();
  }, []);

  const hasKnownProgram = Object.values(KnownProgram).some((program) =>
    state.programs.has(program)
  );
  return (
    <>
      {hasKnownProgram && (
        <Title>
          <Translate msg="myProgram" />
        </Title>
      )}
      {state.programs.has(KnownProgram.CDC_DPP) ? (
        <ProgramPreviewCard program={KnownProgram.CDC_DPP} />
      ) : (
        <ProgramPreviewCard hideActions hideLink />
      )}
    </>
  );
};

export default ProgramOverview;
