import type { FC } from "react";
import React, { useEffect, useState } from "react";
import leftPadNumber from "src/lib/leftPadNumber";

export const formatTimeCountdown = (date?: Date, now = new Date()): string => {
  if (!date) return "";
  const diff = Math.abs(date.getTime() - now.getTime());
  const days = Math.floor(diff / 1000 / 60 / 60 / 24);
  const hours = Math.floor(diff / 1000 / 60 / 60);
  const minutes = Math.floor((diff / 1000 / 60) % 60);
  const seconds = Math.floor((diff / 1000) % 60);

  const hoursLeftOver = hours % 24;
  const minutesLeftOver = minutes % 60;
  const secondsLeftOver = seconds % 60;

  if (days > 0) {
    return `${days} d ${hoursLeftOver} h`;
  }

  if (hours > 0) {
    return `${hours} h ${leftPadNumber(minutesLeftOver, 2)} min`;
  }

  if (minutes > 0) {
    return `${leftPadNumber(minutes, 2)} min ${leftPadNumber(
      secondsLeftOver,
      2
    )} sec`;
  }

  return `${leftPadNumber(seconds, 2)} sec`;
};

const Countdown: FC<{ to?: Date }> = ({ to }) => {
  const [formatted, setFormatted] = useState(formatTimeCountdown(to));

  useEffect(() => {
    if (!to) return;
    const interval = setInterval(() => {
      setFormatted(formatTimeCountdown(to));
    }, 1000);
    return () => clearInterval(interval);
  }, [to]);

  if (!to) {
    return null;
  }

  return <>{formatted}</>;
};

export default Countdown;
