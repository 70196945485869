import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import {
  IconCalendarDate,
  IconCheckmarkCompleted,
  IconClockTimeAnalogWatch,
  IconCrossXCloseMissed
} from "src/constants/icons";
import translate from "src/lib/translate";
import type { AppTaskDetails } from "src/state/TaskManagementBloc/TaskManagementBloc";
import { formatTimeCountdown } from "src/ui/components/Countdown/Countdown";
import Translate from "src/ui/components/Translate/Translate";

const Status = styled.span`
  font-weight: 500;
  margin-top: 0.5rem;
  font-size: 0.84em;
  display: flex;
  gap: 0.4em;
  color: var(--strong-color, inherit);

  &:empty {
    display: none;
  }

  svg {
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.04em;
  }
`;

const ModuleItemStatusDetails: FC<{
  status?: TaskResponse.status;
  taskDetails?: AppTaskDetails;
}> = ({ status, taskDetails }) => {
  if (!status || !taskDetails) {
    return null;
  }

  switch (status) {
    case TaskResponse.status.COMPLETED:
      return (
        <Status>
          <IconCheckmarkCompleted /> <Translate msg="completed" />
        </Status>
      );
    case TaskResponse.status.LOCKED:
      return (
        <Status>
          <IconCalendarDate /> {taskDetails.availableDate?.toLocaleDateString()}
        </Status>
      );
    case TaskResponse.status.AVAILABLE:
    case TaskResponse.status.IN_PROGRESS:
      return (
        <Status>
          <IconClockTimeAnalogWatch />{" "}
          {translate("program_time_to_complete_short", {
            time: formatTimeCountdown(taskDetails.availableDateEnd)
          })}
        </Status>
      );
    case TaskResponse.status.SKIPPED:
      return (
        <Status>
          <IconCrossXCloseMissed /> <Translate msg="missed" />
        </Status>
      );
    default:
      return null;
  }
};

export default ModuleItemStatusDetails;
