import type { ReactElement } from "react";
import { BLOOD_SPOT_SKU } from "src/constants/misc";
import Path from "src/constants/path";
import { NineamComponents } from "src/lib/HtmlParser";

export interface Paragraph {
  title?: string;
  content?: React.JSX.Element | string[] | string;
}

export interface ReadMoreContentItem {
  title: string;
  description: ReactElement | string;
  fullPackageInsertLink?: string;
  paragraphs: Paragraph[];
}

export type ReadMoreContent = Record<string, ReadMoreContentItem>;

export const readMoreContent: ReadMoreContent = {
  fallback: {
    title: "",
    description: "",
    paragraphs: []
  },
  healthy_habits: {
    title: "Healthy Habits",
    description:
      "When it comes to managing diabetes, your day to day habits are just as important as your medications!",
    paragraphs: [
      {
        title: "These include",
        content: ["Eating", "Physical activity", "Stress management", "Sleep"]
      },
      { content: "But there are no one-size-fits-all recommendations." },
      {
        content:
          "Our team of Diabetes Care Specialists, Registered Dietitian Nutritionists and Health Coaches are always available to support you in building and maintaining habits that are most important to you and fit into <strong>YOUR LIFE.</strong>"
      }
    ]
  },
  CARE_TEAM: {
    title: "Access to Care Team",
    description:
      "All team members are always available to you. Send a message whenever you have any questions or concerns, would like support, or just want to say hi!",
    paragraphs: [
      {
        title: "Physicians",
        content:
          "Doctors who review medical assessments, make medical recommendations and prescribe your prescription medications."
      },
      {
        title: "Pharmacists",
        content:
          "Clinicians who help you understand the nitty-gritty details of your prescriptions."
      },
      {
        title: "Diabetes Care Specialists",
        content:
          "Clinicians who are your complete diabetes care experts. They will not prescribe or change your prescription medications."
      },
      {
        title: "Registered Dietitian Nutritionists",
        content:
          "Clinicians who are your experts around everything related to nutrients, food and eating."
      }
    ]
  },
  [BLOOD_SPOT_SKU]: {
    title: "Personalized at-home lab testing",
    description:
      "How to use your at-home test kit: The video below and the instructions in the test kit have step-by-step instructions " +
      `to complete your personalized at-home lab testing as comfortably and accurately as possible. Have questions? <a href='${Path.chat}'>Send us a message</a> anytime!`,
    paragraphs: [
      {
        content: `<div type="${NineamComponents.video}" src="https://vimeo.com/578093700" />`
      }
    ]
  },
  [`${BLOOD_SPOT_SKU}_TREATMENT_PLAN_DETAILS`]: {
    title: "Personalized at-home lab testing",
    description:
      "How to use your at-home test kit: The video below and the instructions in the test kit have step-by-step instructions " +
      `to complete your personalized at-home lab testing as comfortably and accurately as possible.`,
    paragraphs: [
      {
        content: `<div type="${NineamComponents.video}" src="https://vimeo.com/578093700" />`
      }
    ]
  },
  metformin: {
    title: "Metformin",
    description: "For your diabetes",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=2387a618-24d8-4813-aa83-9114edb35374",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood glucose by decreasing the amount of sugar your liver makes."
      },
      {
        title: "How to take it",
        content:
          "It's best to take each pill with food, especially if you've had an upset stomach with it in the past. It's not always necessary to take metformin with food."
      },
      {
        title: "Potential Side Effects",
        content: [
          "Metformin can cause an upset stomach, nausea and diarrhea when you start taking it. If you notice these side effects, make sure you are taking your pill with some food. If it continues for longer than a week or you have other questions about metformin please contact us!",
          "This risk of low blood sugar on Metformin is LOW"
        ]
      }
    ]
  },
  metformin_ER: {
    title: "Metformin ER",
    description: "For your diabetes",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=dd0ee2bf-f658-42dc-976d-49af0b1f32b5",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood glucose by decreasing the amount of sugar your liver makes."
      },
      {
        title: "How to take it",
        content:
          "It's best to take each pill with food, especially if you've had an upset stomach with it in the past. It's not always necessary to take metformin with food."
      },
      {
        title: "Potential Side Effects",
        content: [
          "Metformin can cause an upset stomach, nausea and diarrhea when you start taking it. If you notice these side effects, make sure you are taking your pill with some food. If it continues for longer than a week, or you have other questions about metformin, please contact us!",
          "This risk of low blood sugar on Metformin is LOW"
        ]
      }
    ]
  },
  glipizide: {
    title: "Glipizide",
    description: "For your diabetes",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=dcf426b8-bcdc-8214-a1b6-6388bd3399a0",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood glucose by helping your pancreas make more insulin."
      },
      {
        title: "How to take it",
        content:
          "Take glipizide up to 30 minutes before your meal of the day. If you take it and skip a meal, the risk for a low blood sugar event is high."
      },
      {
        title: "Potential Side Effects",
        content:
          "Glipizide can cause low blood sugar, expecially if you take it and do not eat. Another common side effect is weight gain. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  glipizide_xl: {
    title: "Glipizide XL",
    description: "For your diabetes",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=915636a4-092e-4e2e-9bc0-0d387d996977",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood glucose by helping your pancreas make more insulin."
      },
      {
        title: "How to take it",
        content:
          "Take glipizide up to 30 minutes before your meal. If you take it and skip a meal, the risk for a low blood sugar event is high."
      },
      {
        title: "Potential Side Effects",
        content:
          "Glipizide can cause low blood sugar, especially if you take it and do not eat. Another common side effect is weight gain. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  glimepiride: {
    title: "Glimepiride",
    description: "For your diabetes",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=07ad4366-4b21-f633-49f3-c2b35f88168d",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood glucose by helping your pancreas make more insulin."
      },
      {
        title: "How to take it",
        content:
          "Take glimeperide up to 30 minutes before your meal. If you take it and skip a meal, the risk for a low blood sugar event is high."
      },
      {
        title: "Potential Side Effects",
        content:
          "Glimepiride can cause low blood sugar, especially if you take it and do not eat. Another common side effect is weight gain. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  pioglitazone: {
    title: "Pioglitazone",
    description: "For your diabetes",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=4483b1c6-7095-4a63-8128-ac2d91e11040",
    paragraphs: [
      {
        title: "How it works",
        content: "Lowers blood glucose by improving how your body uses insulin."
      },
      {
        title: "How to take it",
        content:
          "Take pioglitazone with or without food. It works best if taken at the same time every day."
      },
      {
        title: "Potential Side Effects",
        content:
          "Pioglitazone can cause fluid retention (extra puffy looking skin) and weight gain. It can also put you at higher risk for a fracture. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  lisinopril: {
    title: "Lisinopril",
    description: "For your blood pressure",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=e299d477-a612-4af4-b6aa-f5addc8a736d",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood pressure by opening up blood vessels to make it easier for blood to pump through your body."
      },
      {
        title: "How to take it",
        content:
          "Take lisinopril with or without food. It works best if taken at the same time every day."
      },
      {
        title: "Potential Side Effects",
        content:
          "Lisinopril can cause drowsiness, dizziness and a cough. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  losartan: {
    title: "Losartan",
    description: "For your blood pressure",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=e5886220-43b7-46e1-9034-5242ba245bd1",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood pressure by opening up blood vessels to make it easier for blood to pump through your body."
      },
      {
        title: "How to take it",
        content:
          "Take losartan with or without food. It works best if taken at the same time every day."
      },
      {
        title: "Potential Side Effects",
        content:
          "Losartan can cause drowsiness, dizziness and diarrhea. If you notice this, don't hesitate to contact us!"
      }
    ]
  },
  hydrochlorothiazide: {
    title: "Hydrochlorothiazide",
    description: "For your blood pressure",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=babb1b20-fe82-40ba-8e7a-0c56fe433b4a",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood pressure by increasing urination, decreasing the amount of fluid in your blood vessels, which makes it easier to pump."
      },
      {
        title: "How to take it",
        content:
          "Take hydrochlorothiazide with or without food. It works best if taken in the morning every day."
      },
      {
        title: "Potential Side Effects",
        content:
          "Hydrochlorothiazide can cause drowsiness, dizziness, muscle cramps and an irregular heartbeat. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  amlodipine: {
    title: "Amlodipine",
    description: "For your blood pressure",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=3f4703c0-0b9e-41a0-8b70-da26683d9ed2",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers blood pressure by opening up blood vessels to make it easier for blood to pump through your body."
      },
      {
        title: "How to take it",
        content:
          "Take amlodipine with or without food. It works best if taken at the same time every day."
      },
      {
        title: "Potential Side Effects",
        content:
          "Amlodipine can cause drowsiness, dizziness, fluid retention (extra puffy looking skin), an irregular heartbeat and constipation. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  lovaza: {
    title: "Omega-3-acid ethyl esters",
    description: "For your triglycerides",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=787f9929-ef31-42a7-a305-8ba343e39672&audience=professional",
    paragraphs: [
      {
        title: "How it works",
        content:
          'Raises HDL ("good" cholesterol) by decreasing your liver\'s production of triglycerides (a fat in your blood).'
      },
      {
        title: "How to take it",
        content:
          "It's best to take lovaza with food. It works best if taken at the same time every day."
      },
      {
        title: "Potential Side Effects",
        content:
          "If you feel like this medication is not right for you for any reason, please don't hesitate to conact us!"
      }
    ]
  },
  gemfibrozil: {
    title: "Gemfibrozil",
    description: "For your cholesterol",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=75cac02c-56e3-4dfe-b1e2-4ce166b28833",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers cholesterol by decreasing your liver's production of triglycerides (a fat in your blood)."
      },
      {
        title: "How to take it",
        content:
          "Take gemfibrozil 30 minutes before your morning and evening meals."
      },
      {
        title: "Potential Side Effects",
        content:
          "Gemfibrozil can cause upset stomach, stomach cramps, diarrhea or constipation and muscle weakness. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  atorvastatin: {
    title: "Atorvastatin",
    description: "For your cholesterol",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=595ab888-6b55-4642-a32f-e8521821ed81",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers cholesterol by decreasing production of an enzyme that makes cholesterol."
      },
      {
        title: "How to take it",
        content:
          "Take atorvastatin with or without food. It works best if taken at the same time every day."
      },
      {
        title: "Potential Side Effects",
        content:
          "Atorvastatin can cause feelings of weakness and muscle pain. If you notice this, don't hesitate to contact us!"
      }
    ]
  },
  rosuvastatin: {
    title: "Rosuvastatin",
    description: "For your cholesterol",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=5c992d3d-d754-48b6-a267-1451208352ed",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers cholesterol by decreasing production of an enzyme that makes cholesterol."
      },
      {
        title: "How to take it",
        content:
          "Take rosuvastatin with or without food. It works best if taken at the same time every day."
      },
      {
        title: "Potential Side Effects",
        content:
          "Rosuvastatin can cause feelings of weakness and muscle pain. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  lovastatin: {
    title: "Lovastatin",
    description: "For your cholesterol",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=df7ddf4f-d569-431e-81f1-9129d7043150",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers cholesterol by decreasing production of an enzyme that makes cholesterol."
      },
      {
        title: "How to take it",
        content:
          "It's best to take lovastatin with food. It works best if taken at the same time every day. Avoid grapefruit and grapefruit juice."
      },
      {
        title: "Potential Side Effects",
        content:
          "Lovastatin can cause feelings of weakness and muscle pain. Contact us if you have questions about these potential side effects!"
      }
    ]
  },
  simvastatin: {
    title: "Simvastatin",
    description: "For your cholesterol",
    fullPackageInsertLink:
      "https://dailymed.nlm.nih.gov/dailymed/drugInfo.cfm?setid=0376351b-f1a0-43e5-a0b4-533072c49f39",
    paragraphs: [
      {
        title: "How it works",
        content:
          "Lowers cholesterol by decreasing production of an enzyme that makes cholesterol."
      },
      {
        title: "How to take it",
        content:
          "Take simvastatin in the evening with or without food. Avoid grapefruit and grapefruit juice."
      },
      {
        title: "Potential Side Effects",
        content:
          "Simvastatin can cause feelings of weakness and muscle pain. Contact us if you have questions about these potential side effects!"
      }
    ]
  }
};
