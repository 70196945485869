import styled from "@emotion/styled";
import React, { FC } from "react";
import { useBloc } from "src/state/state";
import { ChatBloc } from "../Chat/ChatBloc";

const Position = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: 0.07rem;
  margin-top: -1rem;
  --size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const Badge = styled.div`
  display: grid;
  place-items: center;
  min-width: var(--size);
  min-height: var(--size);
  padding: 0.25rem;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;
  color: white;
  border: 1px solid var(--Greys-Light-Gray, #e6e3db);
  background: var(--Primary-ErrorRed, #bf1902);
  line-height: 0;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  transition: transform 0.2s cubic-bezier(0.25, 1, 0.5, 1);
  transition-property: transform, color;

  &[aria-hidden="true"] {
    transform: scale(0);
    color: transparent;
  }
`;

const NavBadge: FC<{
  path: string;
}> = ({ path }) => {
  const [{ unreadMessages = 0 }] = useBloc(ChatBloc);
  const isChat = path === "chat";

  const show = isChat && unreadMessages > 0;

  return (
    <Position className="nav-badge">
      <Badge aria-hidden={!show}>
        {unreadMessages < 10 ? unreadMessages : "9+"}
      </Badge>
    </Position>
  );
};

export default NavBadge;
