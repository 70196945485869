import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";

import ProgramBloc from "src/state/ProgramBloc/ProgramBloc";
import { programState, useBloc } from "src/state/state";
import ArticleCard from "src/ui/components/UserEducationalFeed/ArticleCard";
import UserEducationalFeedBloc from "src/ui/components/UserEducationalFeed/UserEducationalFeedBloc";
import AsyncContent from "../AsyncContent/AsyncContent";

const ArticleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
`;

const UserEducationalFeedOld: FC = () => {
  const [loading, setLoading] = React.useState(true);
  const [{ articles }, { loadEducationalContentOld }] = useBloc(
    UserEducationalFeedBloc,
    {
      create: () => new UserEducationalFeedBloc()
    }
  );
  const [{ autoProgramDetected }] = useBloc(ProgramBloc, {});

  useEffect(() => {
    setLoading(true);

    async function fetchEducationalContent() {
      const program = programState.state.autoProgramDetected;
      await loadEducationalContentOld({
        program
      });
      setLoading(false);
    }

    void fetchEducationalContent();
  }, [autoProgramDetected]);

  return (
    <div>
      {loading ? (
        <ArticleGrid>
          {new Array(4).fill("").map((_, index) => (
            <AsyncContent key={index} height="6rem" />
          ))}
        </ArticleGrid>
      ) : (
        <ArticleGrid>
          {articles.map((article) => (
            <ArticleCard key={article._id} article={article} />
          ))}
        </ArticleGrid>
      )}
    </div>
  );
};

export default UserEducationalFeedOld;
