import {
  GetLabOrdersResponseItem,
  GetSuggestedTreatmentPlanResponse
} from "@9amhealth/openapi";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { OrderedLabSkus } from "src/constants/labSku";
import checkMedicalDataCollected from "src/lib/checkMedicalDataCollected";
import LabResultsCubit from "src/state/LabResultsCubit/LabResultsCubit";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import UserCubit from "src/state/UserCubit/UserCubit";
import { useBloc } from "src/state/state";
import LabStatusList from "src/ui/components/LabStatusList/LabStatusList";
import AppTopSpacer from "src/ui/components/StyledComponents/AppTopSpacer";
import UserLabResults from "src/ui/components/UserLabResults/UserLabResults";
import { Pad } from "src/ui/styled/Pad";
import { useBloc as useBloc2 } from "@blac/react";

const { provider } = GetLabOrdersResponseItem;
const { status } = GetLabOrdersResponseItem;

export const LabResults: FC<{
  initialEligibility?: GetSuggestedTreatmentPlanResponse.eligibility;
}> = ({ initialEligibility }) => {
  const [
    { allSubscriptions = [] },
    { loadAllSubscriptions, checkUserIsEligible }
  ] = useBloc(SubscriptionCubit);
  const [{ answeredQuestionnaires }, { loadUserAnsweredQuestionnaires }] =
    useBloc(UserCubit);
  const [subscriptionEligibility, setSubscriptionEligibility] = React.useState<
    GetSuggestedTreatmentPlanResponse.eligibility | undefined
  >(initialEligibility);
  const [, { loadUserShipments, getLabTestOrders }] = useBloc2(LabResultsCubit);
  useEffect(() => {
    void checkUserIsEligible().then((e) => {
      setSubscriptionEligibility(
        e
          ? GetSuggestedTreatmentPlanResponse.eligibility.ELIGIBLE
          : GetSuggestedTreatmentPlanResponse.eligibility.DATA_NEEDED
      );
    });
    void loadAllSubscriptions();
    void loadUserShipments();
    void loadUserAnsweredQuestionnaires();
  }, []);

  const medicalDataCollected = useMemo(
    () =>
      checkMedicalDataCollected(
        subscriptionEligibility,
        answeredQuestionnaires
      ),
    [answeredQuestionnaires, subscriptionEligibility]
  );

  const activeA1cLabOrders = getLabTestOrders(
    OrderedLabSkus.A1C,
    [status.COMPLETED, status.DECLINED],
    false
  );

  const renderStatusList =
    allSubscriptions.length > 0 && Boolean(activeA1cLabOrders.length);

  const targetLabOrder = activeA1cLabOrders[0] as
    | GetLabOrdersResponseItem
    | undefined;
  const isHomePhlebotomy =
    targetLabOrder?.provider === provider.BIO_REFERENCE_LAB;

  return (
    <>
      <AppTopSpacer pt />
      <Pad>
        {renderStatusList && (
          <LabStatusList
            isHomePhlebotomy={isHomePhlebotomy}
            medicalDataCollected={medicalDataCollected}
            trackingUrl={targetLabOrder?.trackingUrl}
            orderStatus={targetLabOrder?.status}
          />
        )}

        <UserLabResults />
      </Pad>
      <br />
    </>
  );
};

export default LabResults;
