import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC, ReactNode } from "react";
import React from "react";
import { Link } from "react-router-dom";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import PlayIcon from "src/ui/components/TaskItem/PlayIcon";

const Wrap = styled(Link)`
  display: flex;
  padding: 0.9em 0px 1em 0px;
  align-items: flex-start;
  margin: 1em 0 0;
  gap: 0.8em;
  align-self: stretch;
  border-top: 1px solid var(--greys-light-gray, #e6e3db);
  svg {
    width: 2.4em;
    height: 2.4em;
  }

  &:hover {
    text-decoration: none !important;
  }

  &[data-status="${TaskResponse.status.LOCKED}"] {
    pointer-events: none;
  }
`;

const Small = styled.small`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  flex: 1;
`;

const IconArea = styled.div`
  display: flex;
  align-items: center;
  width: 2.5em;

  [data-status="${TaskResponse.status.LOCKED}"] & {
    filter: grayscale(100%);
  }
`;

const TaskItem: FC<{
  title: ReactNode;
  description?: ReactNode;
  to: string;
  status?: TaskResponse.status;
}> = ({ title, description = "", to, status }) => {
  return (
    <Wrap to={to} data-status={status}>
      <IconArea>
        <AsyncContent width="38px" height="38px" round>
          <PlayIcon />
        </AsyncContent>
      </IconArea>
      <Content>
        <p className="m0 as-strong">
          <AsyncContent width="200px" height="1.2em">
            {title}
          </AsyncContent>
        </p>
        <Small className="m0 color-c-80">
          <AsyncContent height="1em" width="120px">
            {description}
          </AsyncContent>
        </Small>
      </Content>
    </Wrap>
  );
};

export type TaskItemProps = React.ComponentProps<typeof TaskItem>;

export default TaskItem;
