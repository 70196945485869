import { NineSpacer } from "@9amhealth/wcl/generated/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { Locale } from "src/constants/language";
import { getSupportedUserLocale } from "src/lib/i18next";
import translate from "src/lib/translate";
import UserPreferencesCubit, {
  UserPreferenceKeys
} from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import { useBloc } from "src/state/state";
import AppPageTitle from "src/ui/components/AppPageTitle/AppPageTitle";
import { PROFILE_URL } from "src/ui/components/ProfileOverview/ProfileOverview";
import OverlayBackButton from "src/ui/components/StyledComponents/OverlayBackButton";
import Translate from "src/ui/components/Translate/Translate";
import OnEvent from "../OnEvent/OnEvent";
import { Divider } from "src/ui/styled/X";

const NineAnswerItemListWrapper = styled.div`
  background-color: var(--color-white);
  box-shadow: var(--light-shadow);
  border: 2px solid var(--color-white);
  border-radius: var(--border-radius);
`;

const DividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LanguageSettings: FC = () => {
  const language = getSupportedUserLocale();
  const [, { updateUserPreferences }] = useBloc(UserPreferencesCubit);
  const [preferredLanguage, setPreferredLanguage] = React.useState(language);
  const title = translate("language");

  const handleChange = (
    e: CustomEvent<{
      value: Locale;
    }>
  ) => {
    e.stopPropagation();
    const locale = e.detail.value;
    sessionStorage.removeItem("9am.initialLanguage");
    void updateUserPreferences({
      [UserPreferenceKeys.language]: locale
    });
    setPreferredLanguage(locale);
  };

  return (
    <>
      <OverlayBackButton show fallbackUrl={PROFILE_URL} />
      <AppPageTitle title={title}></AppPageTitle>
      <NineSpacer s="xl" />

      <NineAnswerItemListWrapper>
        <nine-answer-item-list
          direction="vertical"
          style={{ backgroundColor: "white" }}
        >
          <OnEvent
            key="language-en"
            events={{
              nineInputChange: handleChange
            }}
          >
            <nine-answer-item
              value={Locale.en}
              name="language-en"
              type="radio"
              center="false"
              styling="false"
              style={{ padding: "1rem 2rem 0" }}
              checked={preferredLanguage === Locale.en ? "true" : "false"}
            >
              <strong> English (United States)</strong>
              <p className="m0 color-c-80 as-little">
                <Translate msg="language.english" />{" "}
              </p>
            </nine-answer-item>
          </OnEvent>
          <DividerWrapper>
            <Divider className="m0 p" style={{ width: "85%" }} />
          </DividerWrapper>
          <OnEvent
            key="language-es"
            events={{
              nineInputChange: handleChange
            }}
          >
            <nine-answer-item
              value={Locale.es}
              name="language-es"
              type="radio"
              center="false"
              style={{ padding: "0 2rem 1rem" }}
              styling="false"
              checked={preferredLanguage === Locale.es ? "true" : "false"}
            >
              <strong> Español</strong>
              <p className="m0 color-c-80 as-little">
                <Translate msg="language.spanish" />{" "}
              </p>
            </nine-answer-item>
          </OnEvent>
        </nine-answer-item-list>
      </NineAnswerItemListWrapper>
    </>
  );
};

export default LanguageSettings;
