import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { IconCaretToRightSmallArrow } from "src/constants/icons";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import translate from "src/lib/translate";
import ProgramBloc, {
  KnownProgram,
  KnownProgramInfo
} from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import ResponsiveImage from "src/ui/components/ResponsiveImage/ResponsiveImage";
import ProgramTaskItem from "src/ui/components/TaskItem/ProgramTaskItem";
import TextWithKeywords from "src/ui/components/TextWithKeywords/TextWithKeywords";
import Translate from "src/ui/components/Translate/Translate";

const Container = styled.div`
  margin: -1rem;

  @media screen and (min-width: ${APP_CONTENT_WIDTH}px) {
    margin: 0;
    padding: 0 var(--space-md);
  }
`;

const ImageTextCard = styled.div`
  position: relative;
`;

const ImageWrapper = styled.div`
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 40.29%,
      rgba(0, 0, 0, 0.58) 62.59%
    );
    pointer-events: none;
    z-index: 1;
    border-radius: var(--border-radius);
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  z-index: 999;
  color: white;
  bottom: 1.5rem;
  left: 1.5rem;
  text-align: left;
  max-width: calc(100% - 3rem);
`;

const ProgramTitle = styled.h1`
  margin-bottom: 0.75rem;
`;

const BlueLink = styled(Link)`
  color: var(--color-sunrise-blue) !important;
  font-weight: bold;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.3em;
`;

const ProgramPreviewCard: FC<{
  program?: KnownProgram;
  hideLink?: boolean;
  hideActions?: boolean;
}> = ({ program, hideActions, hideLink }) => {
  const [
    { userHasNoPrograms, autoProgramDetected },
    { getProgramContent, checkUserProfileProgramData }
  ] = useBloc(ProgramBloc, {});
  const knownProgram =
    program ?? autoProgramDetected ?? KnownProgram.GENERAL_ALL;
  const { title, description, slug, image } = getProgramContent(knownProgram);

  useEffect(() => {
    void checkUserProfileProgramData();
  }, []);

  const definedTitle = useMemo<string>(() => {
    if (!title) {
      return "";
    }

    if (!knownProgram) {
      return title;
    }

    const knownTitle = KnownProgramInfo[knownProgram]?.title;

    if (!knownTitle) {
      return title;
    }

    return translate(knownTitle);
  }, [knownProgram, title]);

  const definedDescription = useMemo<string>(() => {
    if (!description) {
      return "";
    }

    if (!knownProgram) {
      return description;
    }

    const knownDescription = KnownProgramInfo[knownProgram]?.description;

    if (!knownDescription) {
      return description;
    }

    return translate(knownDescription);
  }, [description, knownProgram]);

  if (userHasNoPrograms) {
    return null;
  }

  return (
    <Container>
      <ImageTextCard>
        <AsyncContent check={[image]} height="14rem">
          <ImageWrapper>
            <ResponsiveImage
              alt={definedTitle}
              image={image}
              customAspectRatio={1}
            />
          </ImageWrapper>
        </AsyncContent>

        <TextWrapper>
          <ProgramTitle className="as-h4-large m0">{definedTitle}</ProgramTitle>
          <TextWithKeywords text={definedDescription} />
        </TextWrapper>
      </ImageTextCard>

      <nine-spacer s="md"></nine-spacer>

      {!hideLink && (
        <AsyncContent check={[slug]} width="160px" height="1.2rem">
          <BlueLink to={`/app/program/${slug}`}>
            <Translate msg="program.module.show" />
            <IconCaretToRightSmallArrow />
          </BlueLink>
        </AsyncContent>
      )}

      {!hideActions && (
        <ProgramTaskItem
          program={program}
          select={[
            { status: TaskResponse.status.AVAILABLE },
            { status: TaskResponse.status.IN_PROGRESS },
            { status: TaskResponse.status.LOCKED }
          ]}
        />
      )}
    </Container>
  );
};

export default ProgramPreviewCard;
