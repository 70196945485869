import { TaskResponse } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import { t } from "i18next";
import type { FC, ReactNode } from "react";
import React, { useMemo } from "react";
import type { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import ProgramBloc from "src/state/ProgramBloc/ProgramBloc";
import { useBloc } from "src/state/state";
import AsyncContent from "src/ui/components/AsyncContent/AsyncContent";
import Countdown from "src/ui/components/Countdown/Countdown";
import FileCard from "src/ui/components/FileCard/FileCard";

interface StatProps {
  title: ReactNode;
  subtitle: ReactNode;
}

const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-radius: 8px;
  border: 1px solid var(--greys-light-gray, #e6e3db);
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const Stat = styled.div<{ w?: number; px?: number; py?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.01rem;
  align-self: stretch;
  width: ${(p): string => (p.w ? `${p.w * 100}%` : "100%")};
  --base-p-all: 1rem;
  padding: calc(var(--base-p-all) * ${(p) => p.py ?? 1})
    calc(var(--base-p-all) * ${(p) => p.px ?? 1});

  position: relative;

  hr {
    position: absolute;
    --inset: 1rem;
    height: auto;
    width: auto;
    background: var(--greys-light-gray, #e6e3db);
    margin: 0;

    &.right {
      top: var(--inset);
      bottom: var(--inset);
      width: 1px;
      right: 0;
    }

    &.top {
      left: var(--inset);
      right: var(--inset);
      height: 0.5px;
      top: 0;
    }
  }
`;

const Title = styled.div<{ large?: boolean }>`
  font-weight: 600;
  letter-spacing: -0.02rem;
  font-size: ${(p): string => (p.large ? "1.25rem" : "1rem")};
`;

const Subtitle = styled.div`
  color: var(--greys-charcoal-60, rgba(33, 33, 33, 0.6));
  font-size: 0.75rem;
  font-family: STK Bureau;
  font-weight: 400;
  line-height: 140%;
`;

const ProgramStats: FC<{
  program: KnownProgram;
}> = (props) => {
  const [
    programState,
    {
      getProgramContent,
      countModuleItemsByStatus,
      selectProgramModule,
      getModuleTaskRepresentative
    }
  ] = useBloc(ProgramBloc);
  const programContent = getProgramContent(props.program);
  const modulesCount = programContent.moduleItems?.length ?? 0;
  const completed = countModuleItemsByStatus(
    props.program,
    TaskResponse.status.COMPLETED
  );

  const topCells: StatProps[] = useMemo(() => {
    const selectedModule = selectProgramModule(props.program, [
      { status: TaskResponse.status.AVAILABLE },
      { status: TaskResponse.status.IN_PROGRESS },
      { status: TaskResponse.status.LOCKED }
    ]);
    const { details } = getModuleTaskRepresentative(selectedModule) ?? {};

    const timeCell: StatProps = {
      title: "",
      subtitle: ""
    };

    if (selectedModule?.status === TaskResponse.status.LOCKED) {
      timeCell.title = <Countdown to={details?.availableDate} />;
      timeCell.subtitle = t("program_time_to_next_module_scheduled", {
        module: selectedModule.moduleNumber
      });
    } else if (selectedModule?.status === TaskResponse.status.IN_PROGRESS) {
      timeCell.title = <Countdown to={details?.availableDateEnd} />;
      timeCell.subtitle = t("program_time_to_complete_current_module", {
        module: selectedModule.moduleNumber
      });
    }

    return [
      {
        title: `${completed}/${modulesCount}`,
        subtitle: t("program_modules_completed")
      },
      timeCell
    ];
  }, [completed, modulesCount, programState]);

  const attachments = programContent.attachments ?? [];

  return (
    <AsyncContent height="8.6rem" check={[modulesCount && attachments.length]}>
      <StatsWrapper>
        {topCells.map((cell, i) => (
          <Stat w={1 / topCells.length} key={i}>
            <Title large>{cell.title}</Title>
            <Subtitle>{cell.subtitle}</Subtitle>
            {i !== topCells.length - 1 && <hr className="right" />}
          </Stat>
        ))}
        {attachments.map((attachment, i) => (
          <Stat key={attachment._key}>
            {i !== attachments.length && <hr className="top" />}
            <FileCard attachment={attachment} />
          </Stat>
        ))}
      </StatsWrapper>
    </AsyncContent>
  );
};

export default ProgramStats;
