import styled from "@emotion/styled";
import nineSocialIcon from "src/ui/assets/9am-Social-Avatar.png";
import personIcon from "src/ui/assets/icons/profile-plus.svg";

const FakeButton = styled.button`
  display: flex;
  align-items: center;
  outline: transparent;
  padding: 1em 1.1em;
  border-radius: 0.5em;
  gap: 1em;
  width: 100%;
  box-sizing: border-box;
  color: var(--color-charcoal-80);
  background: #ebebeb;

  &::before {
    content: "";
    background-image: url(${nineSocialIcon});
    width: 24px;
    height: 24px;
    background-size: contain;
    transform: translateY(-1px);
  }

  &::after {
    content: "";
    background-image: url(${personIcon});
    width: 24px;
    height: 24px;
    background-size: contain;
    margin-left: auto;
    transform: translateY(-1px);
  }
`;

export default FakeButton;
