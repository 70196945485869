import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import type { FC } from "react";
import React from "react";
import { Button } from "react-aria-components";
import { useNavigate } from "react-router-dom";
import { APP_CONTENT_WIDTH } from "src/constants/layout";
import DataHubBgImg from "src/ui/assets/images/dataHubBg.jpg";
import Translate from "src/ui/components/Translate/Translate";
import DataHubChart from "./DataHubChart";
import DataHubCubit from "./DataHubCubit";
import DataStatus from "./DataStatus";
import DataTabs from "./DataTabs";
import DataTimeRangeButtons from "./DataTimeRangeButtons";

//#region Styled Components
const DataHubWrapper = styled.div`
  position: relative;
  margin: -1rem calc(-1 * var(--space-md)) 1.5rem;
  padding: 2rem 1.5rem 1.25rem;
  box-shadow: var(--light-shadow);
  overflow: hidden;
  height: 600px;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: #fff;
    z-index: -2;
  }

  @media screen and (min-width: ${APP_CONTENT_WIDTH}px) {
    margin: 0 0 2rem;
    border-radius: 0.5rem;
  }

  /* Hide status if empty state is visible */
  &:has(.empty-state) .status {
    visibility: hidden;
  }

  .chart-tooltip-value {
    em {
      font-style: normal;
      opacity: 0.5;
    }
  }
`;

const StyledButton = styled(Button)`
  background: var(--color-gray-fog);
  border-radius: 0.5rem;
  margin-top: 1.25rem;
  width: 100%;
  color: var(--color-charcoal-80);
  height: 2.75rem;
`;

const StyledTitle = styled.h2`
  padding-top: var(--ion-safe-area-top, 0px);
`;

const StyledImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: 375/200;
  z-index: -1;
`;

const ChartWrapper = styled.div`
  height: 15rem;
  margin: 0 -1.25rem;
`;
//#endregion

const DataHub: FC = () => {
  const [{ activeLoincCode }, { getLoincCodesForUrl }] = useBloc(DataHubCubit);
  const navigate = useNavigate();

  return (
    <DataHubWrapper>
      {/* Background */}
      <StyledImg src={DataHubBgImg} />

      {/* Section title */}
      <StyledTitle>
        <Translate msg="dataHub.title" />
      </StyledTitle>

      {/* Clickable tabs */}
      <DataTabs />

      {/* Status */}
      <DataStatus />

      {/* Chart */}
      <ChartWrapper>
        <DataHubChart />
      </ChartWrapper>

      {/* Time range buttons */}
      <DataTimeRangeButtons />

      {/* Explore Data button */}
      <StyledButton
        onPress={() => {
          navigate(
            `/app/lab-results/list-codes/${getLoincCodesForUrl(activeLoincCode)}`
          );
        }}
      >
        <Translate msg="exploreData" />
      </StyledButton>
    </DataHubWrapper>
  );
};

export default DataHub;
