import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import { DateDuration } from "@internationalized/date";
import clsx from "clsx";
import type { FC } from "react";
import React from "react";
import { Button } from "react-aria-components";
import translate from "src/lib/translate";
import DataHubCubit from "./DataHubCubit";

//#region Styled components
const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: var(--color-gray-fog);
  padding: 0.125rem;
  margin-top: 1.5rem;
`;

const StyledButton = styled(Button)`
  position: relative;
  background: var(--color-gray-fog);
  border-radius: calc(0.5rem - 0.125rem);
  width: 100%;
  color: var(--color-charcoal-60);
  box-shadow: none !important;
  border: 1px solid transparent;
  height: 1.75rem;
  outline: none;

  &:hover {
    border-color: var(--color-gray-lighter);
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 0.3125rem;
    bottom: 0.3125rem;
    width: 1px;
    background: #dcdbd9;
  }

  /* Target last button */
  /* Target button that is right before active button */
  &:last-of-type,
  &:has(+ .active) {
    &::after {
      display: none;
    }
  }

  &.active {
    background: #fff;
    color: var(--color-charcoal);
    border-color: transparent;

    &::after {
      display: none;
    }
  }
`;
//#endregion

export const renderTimeRangeContext = (timeRange?: DateDuration): string => {
  if (!timeRange) {
    return "";
  }

  const [key, value] = Object.entries(timeRange)[0] as [
    key: string,
    value: number
  ];

  return `${value}${key}`;
};

const DataTimeRangeButtons: FC = () => {
  const [
    { activeTimeRange, availableTimeRanges },
    { setActiveInterval, isSameTimeRange }
  ] = useBloc(DataHubCubit);

  return (
    <Wrapper>
      {availableTimeRanges?.map((timeRange) => (
        <StyledButton
          key={renderTimeRangeContext(timeRange.domain)}
          onPress={() => {
            setActiveInterval(timeRange.domain);
          }}
          className={clsx({
            active: isSameTimeRange(timeRange.domain, activeTimeRange?.domain)
          })}
        >
          {translate("timeRange.short", {
            context: renderTimeRangeContext(timeRange.domain)
          })}
        </StyledButton>
      ))}
    </Wrapper>
  );
};

export default DataTimeRangeButtons;
