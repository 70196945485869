import type { PurchaseItemResponse } from "@9amhealth/openapi";
import { checkItemType } from "src/lib/checkItemType";
import type { PaymentListPurchaseItem } from "src/lib/mapPurchaseItemsToSummaryPaymentList";
import type { ParsedPurchaseItemResponse } from "src/lib/parsePurchaseItem";
import { KnownPurchaseItemMetaDataKeys } from "src/lib/parsePurchaseItem";
import translate from "src/lib/translate";
import { ItemType } from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";

const getPillsPerDay = (
  item:
    | ParsedPurchaseItemResponse
    | PaymentListPurchaseItem
    | PurchaseItemResponse
): string | undefined => {
  if (!checkItemType(item.externalItemType, ItemType.MEDICATION_SUGGESTION))
    return;

  const perDay = item.metadata[KnownPurchaseItemMetaDataKeys.intakeTimes] as
    | number
    | undefined;
  const multiplier = (item.metadata[
    KnownPurchaseItemMetaDataKeys.intakeMultiplier
  ] ?? 1) as number;

  if (!perDay) return;

  const totalPerDay = perDay * multiplier;

  return translate("pillPerDayWithCount", { count: totalPerDay });
};

export default getPillsPerDay;
