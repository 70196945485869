import styled from "@emotion/styled";
import type { FC, ReactElement, ReactNode } from "react";
import React from "react";
import { tabBarHeight } from "src/constants/layout";

export const AnimatedPageWrapper = styled.main`
  label: AnimatedPage;
  min-height: calc(100% - ${tabBarHeight}px);
  width: 100%;
  flex: 1;
  height: 100%;
`;

/**
 * Base Page component that animates when it enters/exits from route change
 */
const AnimatedPage: FC<{ children?: ReactNode | undefined }> = ({
  children,
  ...props
}): ReactElement => {
  return <AnimatedPageWrapper {...props}>{children}</AnimatedPageWrapper>;
};

export default AnimatedPage;
