import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect } from "react";
import ShipmentCubit from "src/state/ShipmentCubit/ShipmentCubit";
import { useBloc } from "src/state/state";
import { mapShipmentsToShipmentsList } from "src/ui/components/ShipmentsList/helpers";
import Translate from "src/ui/components/Translate/Translate";
import { LegacyCard } from "src/ui/styled/X";

const ShipmentItemWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const ShipmentWrapper = styled.div`
  padding: 0 var(--space-md);
  margin-top: 3rem;
`;

const ShipmentsList: FC = () => {
  const [{ shipments }, { loadAllShipments }] = useBloc(ShipmentCubit, {
    create: () => new ShipmentCubit()
  });

  useEffect(() => {
    void loadAllShipments();
  }, []);

  const shipmentItemsList = mapShipmentsToShipmentsList(shipments);

  if (!shipmentItemsList.length) {
    return null;
  }

  return (
    <ShipmentWrapper>
      <LegacyCard>
        <h5>
          <Translate msg="shipments_list_tile_title" />
        </h5>
        {shipmentItemsList.map((shipment, index) => (
          <ShipmentItemWrapper key={index}>
            <h6>{shipment.title}</h6>

            <em>{shipment.date}</em>
          </ShipmentItemWrapper>
        ))}
      </LegacyCard>
    </ShipmentWrapper>
  );
};

export default ShipmentsList;
