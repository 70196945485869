const openVcfFile = (e?: MouseEvent): void => {
  e?.preventDefault();
  const url =
    "https://cdn.sanity.io/files/g38rxyoc/production/387bff0b9008295cdb58a7100232230fa36cbf45.vcf";

  // download file
  const link = document.createElement("a");
  document.body.appendChild(link);
  link.href = url;
  link.download = url;
  link.click();
};

export default openVcfFile;
