import { FC } from "react";
import React from "react";
import styled from "@emotion/styled";
import { IconDotsHorizontal } from "src/constants/icons";
import Translate from "src/ui/components/Translate/Translate";
import { ActionConfig } from "src/state/AppointmentsBloc/AppointmentsBloc";

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 0.8rem 1rem;
  gap: 6px;
  border-radius: calc(infinity * 1px);
  background: var(--color-cream-darker);
  font-weight: 500;

  font-size: 0.75rem;
  line-height: 1rem;

  cursor: pointer;

  &:active,
  &:focus,
  &:hover {
    background: var(--color-gray-light);
  }
`;

interface AppointmentActionProps {
  config: ActionConfig | null;
}

export const AppointmentAction: FC<AppointmentActionProps> = (props) => {
  const { config } = props;
  if (!config) {
    return null;
  }

  return (
    <Button onClick={config.onClick} className="appointment-action">
      {config.icon}
      <Translate msg={config.label} />
    </Button>
  );
};

export const MoreAppointmentAction: FC<{ onClick: () => void }> = (props) => {
  return (
    <Button onClick={props.onClick}>
      <IconDotsHorizontal />
    </Button>
  );
};
