import styled from "@emotion/styled";

export const CarouselButtonWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  gap: 0.3rem;
`;

export const CarouselButton = styled.button`
  border-radius: calc(infinity * 1px);
  border: none;
  background: rgba(33, 33, 33, 0.04);
  color: rgba(33, 33, 33, 0.6);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  cursor: pointer;
`;
