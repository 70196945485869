import React from "react";
const IconLock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="31"
    fill="none"
    viewBox="0 0 21 31"
  >
    <path
      fill="#fff"
      d="M3.015 30.832c-1 0-1.753-.258-2.259-.775-.495-.516-.742-1.317-.742-2.404V16.357c0-1.076.29-1.867.871-2.373.592-.516 1.393-.774 2.405-.774l14.378-.033c1.012 0 1.765.259 2.26.775.494.516.742 1.318.742 2.405v11.296c0 1.087-.248 1.888-.742 2.404-.495.517-1.248.775-2.26.775H3.015ZM2.5 14.242v-4.84c0-1.442.215-2.701.645-3.777.441-1.076 1.028-1.969 1.76-2.679A7.215 7.215 0 0 1 7.42 1.365a8.168 8.168 0 0 1 2.92-.533c1.001 0 1.97.178 2.906.533a7.216 7.216 0 0 1 2.517 1.581c.742.71 1.329 1.603 1.76 2.679.44 1.076.66 2.335.66 3.776v4.841H15.49V9.095c0-1.238-.237-2.276-.71-3.115-.474-.85-1.098-1.49-1.872-1.92a5.194 5.194 0 0 0-2.566-.646c-.925 0-1.78.215-2.566.646-.785.43-1.415 1.07-1.888 1.92-.474.84-.71 1.877-.71 3.115v5.147h-2.68Z"
    />
  </svg>
);
export default IconLock;
