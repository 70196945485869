import { useBloc } from "@blac/react";
import React, { memo } from "react";
import { FC } from "react";
import styled from "@emotion/styled";
import Loader from "../Loader/Loader";
import { keyframes } from "@emotion/react";
import clsx from "clsx";
import { KnownProgram } from "src/state/ProgramBloc/ProgramBloc";
import JourneyModuleTrigger from "./JourneyModuleTrigger";
import UserJourneyBloc from "./UserJourneyBloc";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ModuleList = styled.div`
  animation: ${fadeIn} 0.5s ease-in-out;
  h2 {
    text-align: center;
    margin: 0 0 1.2rem 0;
    padding: 0 1.5rem;
    text-wrap: pretty;
    font-size: 2.25rem;

    em {
      display: block;
      text-align: center;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.0175rem;
      text-transform: capitalize;
      font-family: var(--font-family);
      line-height: 2.4;
      color: var(--color-charcoal-80);
    }
  }
`;

const UserJourneyOverview: FC<{ program: KnownProgram }> = memo((props) => {
  const [state, { onTaskStatusUpdated }] = useBloc(UserJourneyBloc, {
    props: {
      journey: "life-balance-12-week",
      taskProgram: props.program
    }
  });

  if (state.loading) {
    return <Loader fixed active />;
  }
  const modules = state.modules ?? [];

  return (
    <ModuleList>
      {modules.map((m, mI) => (
        <div
          key={m.name}
          className={clsx({
            "module-list-item": true,
            last: mI === modules.length - 1
          })}
        >
          <h2>
            <em>{m.subtitle}</em>
            {m.name}
          </h2>
          {m.lessons.map((l, lI) => (
            <div
              key={l.name}
              className={clsx({
                "lesson-list-item": true,
                last: lI === m.lessons.length - 1
              })}
            >
              {l.items.map((i) => (
                <JourneyModuleTrigger
                  key={i.type}
                  item={i}
                  lesson={l}
                  module={m}
                  onTaskStatusUpdated={(status) =>
                    onTaskStatusUpdated(i.task, status)
                  }
                />
              ))}
            </div>
          ))}
        </div>
      ))}
    </ModuleList>
  );
});

export default UserJourneyOverview;
